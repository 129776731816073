import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const Footer = () => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            Footer
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.dark,
        color:'white',
        padding:'60px 40px',
        minHeight:200,
        background:grey[200],
        color:'black'
    },
    links:{
        display:'flex',
        color:'white',
        fontFamily:theme.typography.fontFamily,
        color:'white',
        textDecoration:'none',
        [theme.breakpoints.down('sm')]:{
            justifyContent:'center'
        }
    },
    link:{
        marginRight:8,
        //fontWeight:500,
        color:'white',
        textDecoration:'none'
    },
    media_icon:{
        width:40
    },
    legal_container:{
        marginTop:16
    }
}))
 
export default Footer;