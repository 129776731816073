import moment from "moment"
import { useState, useEffect } from "react"
import { LANG_LOCALE, public_server } from "../../../config"

const useArticlesView = ({ language, content}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [articles, setArticles] = useState([])
    

    useEffect(() => {
        window.scrollTo(0,0)
        actions.onInitModule()
    }, [language])
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                const params = {
                    //_sort:'id:desc',
                    _locale:LANG_LOCALE[language]
                }

                const request = await public_server.get('/articles', {params:{...params}})
                //console.log(request.data)
                request.data.sort((a,b) => {
                    if(a.id < b.id) return -1;
                    if(a.id > b.id) return 1;
                    return 0;
                })

                let _articles = []
                for(let i=0; i<request.data.length; i++){
                    const current = request.data[i];
                    const current_date = moment(current.published_date)

                    const current_month = current_date.month()+1
                    const current_day = current_date.date()

                    const temp = {
                        id: current.id,
                        title: current.title,
                        content: current.content,
                        year: current_date.year(),
                        month: current_month < 10 ? `0${current_month}` : current_month,
                        day: current_day < 10 ? `0${current_day}` : current_day,
                        first_month:false,
                        first_year:false
                    }
                    _articles.push(temp)
                }

                console.log('esto es nuevo')

                for(let i=0; i<_articles.length; i++){
                    const current = _articles[i];
                    let first_year = true
                    if(i > 0){
                        for(let j=i-1; j>=0; j--){
                            const prev = _articles[j]
                            if((prev.year === current.year)) first_year = false;
                        }
                    }
                    _articles[i].first_year = first_year
                }

                for(let i=0; i<_articles.length; i++){
                    const current = _articles[i];
                    let first_month = true
                    if(i > 0){
                        for(let j=i-1; j>=0; j--){
                            const prev = _articles[j]
                            if((prev.year === current.year) && (prev.month === current.month)) first_month = false;
                        }
                    }
                    _articles[i].first_month = first_month
                }

                setArticles(_articles)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        },
    }

    const system = {loading, sending, error}
    const view_data = {articles}

    return {system, view_data, actions}
}

export default useArticlesView
