import React, { useState, useEffect } from 'react';
import { Button, Card, ClickAwayListener, Grid, Grow, Icon, makeStyles, MenuItem, Popper, TextField } from '@material-ui/core';
import cx from 'classnames'
import { blueGrey, grey } from '@material-ui/core/colors';

const MemberListMobile = ({employees, selected, onChange}) => {

    //const {selected, onChange, options} = props

    const [anchorEl, setAnchorEl] = useState(null)
    
    const classes = useStyles()

    const onSelectItem = (item) =>{
        setAnchorEl(null)
        onChange(item)
    }

    const buttonContent = (<div className={classes.list}>
        <Grid container direction='column'>
        {employees.map((item, key) => {
            return (<Grid item xs={12}>
                <div 
                number={item.number} 
                selected={item.id === selected} 
                className={classes.menuItem}
                style={{borderBottom: key === employees.length-1 ? 'none' : undefined}}
                onClick={() => onSelectItem(item)}
                >
                    {item.name}
                </div>
                </Grid>)
        })}
        </Grid>
    </div>)

    const onGetName = (op , sel) => {
        let name= null
        op.forEach(element => {
            if(sel.id === element.id)
            name = element.name
        });
        return name
    }

    return ( 
        <div className={classes.root} >
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-start' style={{zIndex:10, width:'90%'}} >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                    {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <div onClick={(e) => setAnchorEl(e.currentTarget)} >
                <Button className={classes.selected} fullWidth>    
                    {onGetName(employees, selected)}
                    <Icon style={{marginLeft:20}} >expand_more</Icon>                    
                </Button>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        marginBottom:20
        
    },
    selected:{
        background:'transparent',
        color:'white',
        padding:'8px 24px',
        border:'1px solid #084EB6'
    },
    list:{
        background:grey[800],
        color:grey[300],
        width:'100%',
        maxHeight:250,
        overflowY:'auto',
        border:'1px solid #084EB6'
    },
    menuItem:{
        fontFamily:theme.typography.fontFamily,
        fontWeight:300,
        background:'#1C1C1C',
        color:grey[50],
        padding:'16px 8px',
        textAlign:'center',
        fontSize:'1rem',
        cursor:'pointer',
        borderBottom:'1px solid #084EB6'
    },
}))
 
export default MemberListMobile;



const useButtonStyles = makeStyles(theme => ({
    root:{position:'relative', zIndex:2},
    button:{
        //background:blueGrey[100],
        color:'rgba(0,0,0,0.5)',
        //borderRadius:8,
        padding:'8px 16px',
        '&:hover':{background:blueGrey[200]},
        width:'100%'
    },
    selected:{
        color:'white',
        //background:'#084EB6',
        //fontWeight:500,
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        //'&:hover':{background:theme.palette.secondary.dark}
        //border:`1px solid ${theme.palette.primary.main}`
    }
}))

const CustomButton = props => {
    const classes = useButtonStyles()
    const {children, selected, onClick} = props



    return(       
        <Button onClick={onClick} className={cx({
            [classes.button]:true,
            [classes.selected]:selected
        })}>{children}</Button> 
    )
}