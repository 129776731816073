import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import ValuesView from '../views/Values/ValuesView'
import HomeView from '../views/HomeView/HomeView'
import OfficeView from '../views/Office/OfficeView'
import ContactView from '../views/Contact/ContactView'
import PracticesView from '../views/Practices/PracticesView'
import TeamView from '../views/Team/TeamView'
import ArticlesView from '../views/Articles/Articles/ArticlesView'
import ArticleView from '../views/Articles/Article/ArticleView'
import TestimonyView from '../views/Testimony/TestimonyView'

const DashboardRouter = props => {
    return(
        <Switch>
            <Route exact path='/team' component={TeamView} />
            <Route exact path='/practices' component={PracticesView} />
            <Route exact path='/contact' component={ContactView} />
            <Route exact path='/values' component={ValuesView} />
            <Route exact path='/office' component={OfficeView} />
            <Route exact path='/news/:id' component={ArticleView} />
            <Route exact path='/news' component={ArticlesView} />
            <Route exact path='/testimony' component={TestimonyView} />
            <Route exact path='/' component={HomeView} />
            <Redirect to='/' />
        </Switch>
    )
}

export default DashboardRouter

