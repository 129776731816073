

import React from 'react';
import { Grid, Hidden, Icon, makeStyles, Typography } from '@material-ui/core';

const AdminConstSection = ({content}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <Hidden smDown>
            <div >
                <div className={classes.pattern_container}>
                    <img alt='' className={classes.pattern} src={content.pattern}/>
                </div>
                
                    <Typography variant='h6' className={classes.title}>
                        {content.title}
                    </Typography> 
                
                 
                <div className={classes.image_container}>
                    <img alt='' className={classes.image} src={content.image}/>
                </div>    
            </div>
            </Hidden>
            <div className={classes.overview}>
                {content.intro}
            </div>
            <div>
               
                <Typography className={classes.paragraph}>
                    {content.paragraph1}
                </Typography>
                <Typography className={classes.paragraph}>
                    {content.paragraph2}
                </Typography>
                <Typography className={classes.paragraph}>
                    {content.paragraph3}
                </Typography>
                <Typography className={classes.paragraph}>
                    {content.paragraph4}
                </Typography>
                    
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        //width:800,
        marginBottom:120,
        [theme.breakpoints.down('md')]:{
            width:'100%',
        },
    },
    header:{
        display:'flex',
        flexWrap:'no-wrap',
        alignItems:'center'
    },
    title:{
        width:280,
        fontFamily:theme.typography.fontFamily,
        //border:'1px solid white'
    },
    pattern_container:{
        flex:1,
        //marginLeft:40
        marginBottom:40,
    },
    pattern:{
        width:'100%',
    },
    image_container:{
        marginTop:40
    },
    image:{
        width:'100%'
    },
    overview:{
        //margin:'40px 0px',
        marginBottom:40,
        //borderTop:'1px solid white',
        borderBottom:'1px solid white',
        fontFamily:'alda',
        fontSize:18,
        padding:'40px 0px',
        fontStyle:'italic'
        
    },
    paragraph:{
        marginBottom:16,
        fontSize:16
    },
    list_wrapper:{
        marginLeft:54,
        marginRight:16
    },
    list:{
        fontSize:16,
        fontFamily:theme.typography.fontFamily,
        fontWeight:300
    },
    list_item:{
        marginBottom:16
    }

}))
 
export default AdminConstSection;