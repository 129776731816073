import shipping_icon from '../../assets/icons/shipping.svg'
import center_call_icon from '../../assets/icons/center-call.svg'
import offers_icon from '../../assets/icons/offers.svg'

import baby_icon from '../../assets/icons/baby.png'
import drugs_icon from '../../assets/icons/pill.png'
import aids_icon from '../../assets/icons/first-aids.png'
import nutrition_icon from '../../assets/icons/nutrition.png'
import skin_icon from '../../assets/icons/skin.png'
import mainImage from '../../assets/values/main-image.jpg'
import secondaryImage from '../../assets/values/secondary-image.jpg'
import pattern from '../../assets/values/pattern.svg'

export const translations = {
    main_image:mainImage,
    second_image:secondaryImage,
    pattern:pattern,
    radius_pattern:null,
    confidence:{
        title:'Confianza',
        text:'La confianza de nuestros clientes es invaluable; se construye en décadas y se pierde en un instante.'
    },
    solution:{
        title:'Solución',
        text:'Los problemas de nuestros clientes son nuestros problemas, y todo problema tiene una solución.'
    },
    dedication:{
        title:'Dedicación',
        text:'No obstante la diversidad de factores que inciden en un litigio, debemos asumir que el éxito de la disputa depende exclusivamente de nosotros, de nuestro esfuerzo y dedicación.'
    },
    honesty:{
        title:'Honestidad',
        text:'La honestidad lo es todo; abogado postulante sin credibilidad, que elija otra profesión.'
    },
    ethics:{
        title:'Ética',
        text:'Nuestras acciones están sujetas a los estándares éticos y profesionales más altos.  Nada justifica sacrificar nuestra integridad.'
    },
    excellence:{
        title:'Excelencia',
        text:'La excelencia es el sello de la casa, por lo que no hay litigios de menor o mayor relevancia.'
    },
    undertaking:{
        title:'Compromiso',
        text:'Nuestra profesión exige disponibilidad inmediata y total compromiso profesional.  Nos debemos a nuestros clientes, y estamos para servirles.'
    },
    sensitivity:{
        title:'Sensibilidad',
        text:'Tenemos el privilegio de poder ayudar.  Seamos sensibles a la realidad social, y asistamos a personas cuyo acceso a la justicia puede verse comprometido.'
    },
    
}

export default translations