import React, {useState, useEffect} from 'react';
import { AppBar, Fade, Grid, Hidden, Icon, IconButton, makeStyles, Paper, Popover, Popper, Typography, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {translations} from '../../../texts/esp/general-translations'

import cx from 'classnames'
import { grey, red } from '@material-ui/core/colors';
import { DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import { onClearMemory } from '../../../shared/utility';
import system_translations from '../../../texts/system_translations';

const Topbar = ({user, history, is_auth, cart, onUpdateSignInModal, onUpdateUserData, onUpdateAuthStatus, language, onUpdateDrawerStatus, onUpdateLanguage}) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const trigger = useScrollTrigger({disableHysteresis:true, threshold:100})


    const content = system_translations[language].general.topbar

    const isURLSelected = (path) => {
        return history.location.pathname.split('/')[1] === path.split('/')[1];
    }

    const onChangeView = (url) => {
        history.push(url)
    }

    const onChangeLanguage = async(lang) => {
        console.log('something new')
        await localStorage.setItem('lang', lang)
        onUpdateLanguage(lang)
        //window.location.reload()
    }


    const current_path = history.location.pathname

    let _trigger = trigger || current_path !== '/'
    return ( 
        <AppBar className={classes.appbar}>
            <div className={classes.root}>
                <Grid container alignItems='center'>
                    <Grid item>
                        <div className={classes.logo_container} onClick={()=>onChangeView('/')}>
                            <img alt='' src={content.logo} className={classes.logo} />
                        </div>
                    </Grid>
                    <Grid item xs></Grid>
                    <Hidden mdDown>
                    {content.titles.map((item) => {

                        const isactive = isURLSelected(item.url)

                        return(
                            <Grid item>
                                <div className={cx({[classes.topbar_item]:true, [classes.topbar_item_active]:isactive})} onClick={()=>onChangeView(item.url)}
                                    style={{borderLeft:item.url === '/office' ? '1px solid white': ''}}>
                                    <Typography align='center'  style={{textAlign:'center', fontSize:'0.850rem'}} >{item.title}</Typography>
                                </div>
                            </Grid>
                        )
                    })} 
                    </Hidden>  
                    <Hidden mdDown>
                        <Grid item>
                            <LanguageMenu2 language={language} onChangeLanguage={onChangeLanguage}/>
                        </Grid>  
                    </Hidden>  
                    <Hidden lgUp>
                        <Grid item>
                            <IconButton
                                style={{color:'white'}}
                                aria-label="open drawer"
                                edge="end"
                                onClick={() =>onUpdateDrawerStatus(true)}
                                className={classes.menu}
                            >
                                <Icon>menu</Icon>
                            </IconButton>
                        </Grid>
                    </Hidden>    
                </Grid>
            </div>
        </AppBar>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'20px 80px',
        paddingRight:24,
        //margin:'auto',
        boxSizing:'border-box',
        position:'relative',
        width:'100%',
        [theme.breakpoints.down('md')]:{
            padding:'20px 20px'
        },
    },
    button:{
        background:'white',
        color:'black',
        '&:hover':{
            background:'white',
        }
    },
    appbar:{
        background:'#1C1C1C',
        border:'none',
        boxShadow:'none',
        //boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        height:76,
        color:'grey',
    },
    appbar_mono:{
        //background:'transparent',
        background:'#1C1C1C',
        background:grey[200],
        boxShadow:'none',
        color:'grey',
    },
    logo:{
        width:150,
        //marginTop:16,
        //paddingTop:8,
        //background:'red'
    },
    logo_container:{
        paddingTop:16,
        cursor:'pointer'
        //background:'red'
    },
    searchbar:{
        position:'absolute',
        top:24,
        left:'50%',
        transform:'translateX(-50%)'
    },
    topbar_item:{
        //width:100,
        padding:'0px 32px',
        borderRight:'1px solid white',
        color:'white',
        textTransform:'uppercase',
        cursor:'pointer',
        maxWidth:150,
        
    },
    topbar_item_active:{
        color:'#5F5F5F',
        borderRight:'1px solid #5F5F5F',
    }
}))

const mapStateToProps = state => {
    return {
        user:state.user,
        is_auth:state.is_auth,
        language:state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSignInModal: (signin_modal) => dispatch({type:actionTypes.MODAL_UPDATE_SIGNIN, signin_modal}),
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status}),
        onUpdateLanguage: (language) => dispatch({type:actionTypes.SYS_UPDATE_LANGUAGE, language})

    }
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)( Topbar));


const LanguageMenu = ({language, onChangeLanguage}) => {

    const classes = useLanguageStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)


    const onOpenMenu = (el) => {
        //console.log('abrir')
        setAnchorEl(el.currentTarget)
        setOpen(true)
    }

    const onCloseMenu = (el) => {
        //console.log('cerrar')
        setAnchorEl(null)
        setOpen(false)
    }

    const onInnerChangeLanguage = (lang) => {
        onCloseMenu()
        onChangeLanguage(lang)
    }

    return(
        <div >
            <Popover open={open} anchorEl={anchorEl} placement={'bottom-end'} onClose={onCloseMenu} 
                anchorOrigin={{vertical: 'bottom',horizontal: 'center'}}
                transformOrigin={{vertical: 'top',horizontal: 'center'}} classes={{paper:classes.paper}}>
                    <div style={{width:40}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.menu_item} style={{borderBottom:'1px solid white'}}
                                onClick={() => onInnerChangeLanguage('es')}>
                                    ES
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.menu_item} onClick={() => onInnerChangeLanguage('en')}>
                                    EN
                                </div>
                            </Grid>
                        </Grid>
                    </div>
            </Popover>
            <div className={classes.root} onClick={onOpenMenu} style={{borderColor:language === 'es' ? red[400] : '#184EA2'}}>
                <div className={classes.text_container} >
                    {language === 'es' ? 'ES' : 'EN'}
                </div>
            </div>
            
        </div>
    )
}


const LanguageMenu2 = ({language, onChangeLanguage}) => {

    const classes = useLanguageStyles()

    return(
        <div style={{paddingLeft:16}}>
            <Grid container >
                <Grid item>
                    <div className={classes.root} onClick={()=>onChangeLanguage('es')} style={{borderColor:language === 'es' ? red[400] : 'transparent'}}>
                        <div className={classes.text_container} >
                            ES
                        </div>
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.root} onClick={()=>onChangeLanguage('en')} style={{borderColor:language === 'en' ?'#184EA2' : 'transparent'}}>
                        <div className={classes.text_container} >
                            EN
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const useLanguageStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        width:36,
        height:36,
        //marginLeft:16,
        marginLeft:8,
        color:'white',
        //paddingLeft:36,
        cursor:'pointer',
        border:'1px solid white',
        borderRadius:'50%'
    },
    text_container:{
        position:'absolute', top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    paper:{
        background:'transparent',
        color:'white',
        marginTop:16
    },
    menu_item:{

        padding:'12px 0px',
        paddingLeft:12,
        textAlign:'flex-end',
        cursor:'pointer'
    }

}))


