import { Grid, Typography, makeStyles, Slide, Fade, Hidden, useTheme, useMediaQuery } from "@material-ui/core"
import { useEffect, useState } from "react"
import InViewMonitor from 'react-inview-monitor';


const ValuesDesktop = ({ content }) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])


    const [section_dispatcher, setSectionDispatcher] = useState({
        imageA:false,
        imageB:false,
        imageC:false,
        second_row:false,
        third_row:false,
    })

    const onUpdateDispatcher = (data) => {
        setSectionDispatcher({...section_dispatcher, ...data})
    }

    const leftContent = (
        <div className={classes.subroot_left}>
            <div style={{position:'relative'}}>
                <Slide in timeout={1500} direction='right' >
                    <div>
                        <img src={content.main_image} width='100%' />
                    </div>
                </Slide>
                <div className={classes.vertical_image_container}>
                    <Slide in timeout={1000} direction='right' >
                        <div><img src={content.second_image} width='100%' className={classes.vertical_image}/></div>
                    </Slide>
                </div>
                <div className={classes.pattern_container}>
                    <Fade in timeout={3000} >
                        <div><img src={content.pattern} width='100%' className={classes.pattern_image}/></div>
                    </Fade>
                    
                </div>
                <div className={classes.circle_container}/>
            </div>
            
        </div>
    )

    return(
        <div className={classes.root} >
            <div className={classes.rootcontainer} >
                <Hidden smDown>
                    {leftContent}
                </Hidden>
                <div className={classes.subroot}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Fade in timeout={2000}>
                                <div>
                                    <Grid container>
                                        <Grid item xs={12} md={3}>
                                            <div>
                                                <div className={classes.first_row}>
                                                    <div className={classes.deco1}></div>
                                                    <div className={classes.container01}>
                                                        <Typography variant='h1'>01</Typography>
                                                    </div>
                                                </div>
                                                <div className={classes.second_row}>
                                                    <div style={{padding:'20px 0px', width:'100%'}}>
                                                        <div className={classes.header}>
                                                            <Typography variant='subtitle2'>{content.confidence.title}</Typography>
                                                        </div>
                                                        <Typography>{content.confidence.text}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.first_row}>
                                                <div className={classes.deco2}></div>
                                                <div className={classes.container02}>
                                                    <Typography variant='h1'>02</Typography>
                                                </div>
                                            </div>
                                            <div className={classes.second_row}>
                                                <div style={{padding:!ismobile ? '20px 40px' : '20px 0px'}}>
                                                    <div className={classes.header} >
                                                        <Typography variant='subtitle2'>{content.solution.title}</Typography>
                                                    </div>
                                                    <Typography>{content.solution.text}</Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className={classes.first_row}>
                                                <div className={classes.deco3}></div>
                                                <div className={classes.container03}>
                                                    <Typography variant='h1' style={{color:'#1C1C1C'}}>0</Typography>
                                                    <Typography variant='h1' style={{color:'#184EA2'}}>3</Typography>
                                                </div>
                                            </div>
                                            <div className={classes.second_row}>
                                                <div style={{padding:'20px 0px', width:!ismobile ? '73%' : '100%'}}
                                                >
                                                    <div className={classes.header} >
                                                        <Typography variant='subtitle2'>{content.dedication.title}</Typography>
                                                    </div>
                                                    <Typography>{content.dedication.text}</Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                
                                </div>
                            </Fade>
                        </Grid>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={()=> onUpdateDispatcher({second_row:true})} >
                                <Fade in={section_dispatcher.second_row} timeout={2000}>
                                    <div>
                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <div className={classes.third_row}>
                                                    <div className={classes.deco4}></div>
                                                    <div className={classes.container04}>
                                                        <Typography variant='h1'>04</Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <div className={classes.third_row}>
                                                    <div style={{padding: !ismobile ? '20px 40px' : 0}}>
                                                        <div className={classes.header} >
                                                            <Typography variant='subtitle2'>{content.honesty.title}</Typography>
                                                        </div>
                                                        <Typography>{content.honesty.text}</Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className={classes.third_row}>
                                                    <div style={{display:'flex', flexDirection:!ismobile ?'row' : 'column'}}>
                                                        <div style={{position:'relative', width: !ismobile ?'50%' : '100%', height:!ismobile ?'auto' : 180}}>
                                                            <div className={classes.deco5}></div>
                                                            <div className={classes.container05}>
                                                                <Typography variant='h1'>05</Typography>
                                                            </div>
                                                        </div>
                                                        <div style={{display:'flex', alignItems:'center', width:!ismobile ?'50%' : '100%'}}>
                                                            <div>
                                                                <div className={classes.header} style={{paddingTop:60}} >
                                                                    <Typography variant='subtitle2'>{content.ethics.title}</Typography>
                                                                </div>
                                                                <Typography>{content.ethics.text}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Fade >
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={()=> onUpdateDispatcher({third_row:true})} >
                                <Fade in={section_dispatcher.third_row} timeout={2000}>
                                    <div>
                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <div className={classes.fourth_row}>
                                                    <div style={{height:!ismobile ?'auto' : 180, marginTop:!ismobile ? 0 : 150}}>
                                                        <div className={classes.deco6}></div>
                                                        <div className={classes.container06}>
                                                            <Typography style={{color: 'transparent', WebkitTextStroke:'1px white'}} variant='h1'>06</Typography>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className={classes.fifth_row}>
                                                    <div style={{padding:'20px 0px', width: !ismobile ? '80%' : '100%'}}>
                                                        <div className={classes.header}>
                                                            <Typography variant='subtitle2'>{content.excellence.title}</Typography>
                                                        </div>
                                                        <Typography>{content.excellence.text}</Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <div className={classes.fourth_row}>
                                                    <div className={classes.deco7}></div>
                                                    <div className={classes.container07}>
                                                        <Typography variant='h1'>07</Typography>
                                                    </div>
                                                </div>
                                                <div className={classes.fifth_row}>
                                                    <div style={{padding: !ismobile ? '20px 40px' : 0}}>
                                                        <div className={classes.header}>
                                                            <Typography variant='subtitle2'>{content.undertaking.title}</Typography>
                                                        </div>
                                                        <Typography>{content.undertaking.text}</Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className={classes.fourth_row}>
                                                    <div className={classes.deco8}></div>
                                                    <div className={classes.container08}>
                                                        <Typography variant='h1'>08</Typography>
                                                    </div>
                                                </div>
                                                <div className={classes.fifth_row}>
                                                    <div style={{padding:'20px 0px', width:'55%'}}>          
                                                        <div className={classes.header}>
                                                            <Typography variant='subtitle2'>{content.sensitivity.title}</Typography>
                                                        </div>
                                                        <Typography>{content.sensitivity.text}</Typography>                    
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Fade >
                            </InViewMonitor>
                        </Grid>
                    </Grid>
                </div>
            </div>
            
            {/*<div className={classes.bottom_rectangle}></div>*/}
        </div>
    )

}

export default ValuesDesktop

const useStyles = makeStyles(theme => ({
    root:{
        //padding:'100px 0px 0px',
        marginTop:220,
        width:'100%',
        background:'#1C1C1C',  
        boxSizing:'border-box',
        minHeight:'100vh',
        [theme.breakpoints.down('sm')]:{
            marginTop:180,
            padding:32
        },
        //color:'#F4F4F4',
    },
    subroot_left:{
        flex:1,
        paddingRight:'9%',
        boxSizing:'border-box',
        position:'relative',
        [theme.breakpoints.down('sm')]:{
            paddingRight:0
        },
    },
    vertical_image_container:{
        width:'40%',
        //paddingTop:'50%',
        //border:'1px solid white',
        position:'absolute',
        zIndex:2,
        bottom:0,
        right:0,
        transform:'translate(30%,80%)'

    },
    pattern_container:{
        width:'80%',
        //paddingTop:'50%',
        //border:'1px solid white',
        position:'absolute',
        zIndex:2,
        bottom:0,
        left:0,
        transform:'translate(0%,150%)',
        zIndex:3,

    },
    circle_container:{
        width:'80%',
        paddingTop:'80%',
        //paddingTop:'50%',
        border:'1px solid white',
        position:'absolute',
        zIndex:2,
        bottom:0,
        left:0,
        transform:'translate(-40%,130%)',
        zIndex:1,
        background: '#184EA2',
        borderRadius:'50%',
        filter:'blur(100px)'
    },
    rootcontainer:{
        //border:'1px solid white',
        display:'flex',
        width:'100%'
    },
    subroot:{
        //visibility:'hidden',
        //border:'1px solid white',
        width:1200,
        paddingRight:'100px',
        boxSizing:'border-box',
        color:'white',
        //margin:'auto',
        [theme.breakpoints.down('md')]:{
            width:'100%',
            margin:0,
            boxSizing:'border-box',
            //border:'1px solid white'
            //background:'grey'
         },

         [theme.breakpoints.down('sm')]:{
            width:'100%',
            margin:0,
            boxSizing:'border-box',
            //border:'1px solid white',
            paddingRight:'0px',
            //background:'grey'
         },

    },
    bottom_rectangle:{
        width:'100%',
        height:50,
        background:'#303030',
        marginTop:200
    },
    header:{
        padding:'20px 0px',
        textTransform:'uppercase'
    },
    text_column2:{
        padding:'0px 20px'
    },
    first_row:{
        height:280,
        width:'100%',
        position:'relative',
        //background:'white'
    },
    second_row:{
        height:220,
        width:'100%',
        position:'relative',
        //background:'green'
    },
    third_row:{
        height:180,
        width:'100%',
        position:'relative',
        margin:'40px 0px 100px',
        [theme.breakpoints.down('sm')]:{
            margin: '16px 0px 0px',
         },
        //background:'yellow'
    },
    fourth_row:{
        height:270,
        width:'100%',
        position:'relative',
        //background:'white'
    },
    fifth_row:{
        //height:180,
        width:'100%',
        position:'relative',
        //background:'white'
    },
    deco1:{
        width:'33%',
        height:'100%',
        background:'repeating-linear-gradient(80deg, transparent, transparent 3%, #084EB6 4%)'
    },
    deco2:{
        position:'absolute',
        left:40,
        bottom:0,
        width:'80%',
        height:'90%',
        background:'repeating-linear-gradient(70deg, transparent, transparent 2%, #C4C4C4 3%)',
        [theme.breakpoints.down('sm')]:{
            left:0,
            width:'100%',
         },
    },
    deco3:{
        position:'absolute',
        bottom:0,
        width:'100%',
        height:'60%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 2%, #084EB6 3%)',
        [theme.breakpoints.down('sm')]:{
            left:0,
            width:'100%',
         },
    },
    deco4:{
        position:'absolute',
        top:'50%',
        width:'100%',
        height:'50%',
        background:'repeating-linear-gradient(45deg, transparent, transparent 3%, #5F5F5F 4%)'
    },
    deco5:{
        position:'absolute',
        top:0,
        width:'50%',
        height:'100%',
        zIndex:2,
        background:'repeating-linear-gradient(-70deg, transparent, transparent 2%, #084EB6 3%)',
        [theme.breakpoints.down('sm')]:{
            width:30,
            height:200,
         },
    },
    deco6:{
        position:'absolute',
        bottom:'5%',
        width:'80%',
        height:'60%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 3%, #5F5F5F 4%)'
    },
    deco7:{
        position:'absolute',
        zIndex:2,
        width:'40%',
        height:'100%',
        background:'repeating-linear-gradient(80deg, transparent, transparent 2%, #C4C4C4 3%)',
    },
    deco8:{
        position:'absolute',
        bottom:0,
        width:'70%',
        height:'80%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 2%, #084EB6 3%)'
    },
    container01:{
        color:'#184EA2',
        position:'absolute',
        bottom:'10%',
        left:'15%',
    },
    container02:{
        color:'#1C1C1C',
        position:'absolute',
        bottom:'5%',
        left:'30%',
    },
    container03:{
        //color:'#1C1C1C',
        position:'absolute',
        bottom:12,
        left:4,
        display:'flex'
    },
    container04:{
        color:'#5F5F5F',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    },
    container05:{
        color:'#5F5F5F',
        position:'absolute',
        top:'50%',
        left:0,
        transform:'translateY(-50%)',
        zIndex:1,
    },
    container06:{
        color:'#5F5F5F',
        position:'absolute',
        left:'50%',
        bottom:-28,
        transform:'translateX(-50%)',
        '&:webkitTextStroke':'2px black',
        
    },
    container07:{
        color:'#184EA2',
        position:'absolute',
        left:'50%',
        bottom:-28,
        transform:'translateX(-50%)',
    },
    container08:{
        color:'#184EA2',
        position:'absolute',
        left:0,
        bottom:-28,
        //transform:'translateX(-50%)',
    },
    
    
}))