import React from 'react';
import { Drawer, alpha, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions';
import {translations} from '../../../texts/esp/general-translations'
import cx from 'classnames';
import { grey } from '@material-ui/core/colors';
import { withRouter } from 'react-router';
import system_translations from '../../../texts/system_translations';
import LanguageMenu from './LanguageMenu';

const Sidebar = ({drawer_status, history, ismobile, language, onUpdateDrawerStatus, onUpdateLanguage}) => {

    
    const classes = useStyles()
    const content = system_translations[language].general.topbar

    const isURLSelected = (path) => {
        return history.location.pathname.split('/')[1] === path.split('/')[1];
    }

    const onChangeView = (url) => {
        history.push(url)
        onUpdateDrawerStatus(false)
    }

    const onChangeLanguage = async(lang) => {
        await localStorage.setItem('lang', lang)
        onUpdateLanguage(lang)
        onUpdateDrawerStatus(false)
        //window.location.reload()
    }


    return ( 
        <div>
            <Drawer open={drawer_status} 
                variant={'temporary' }
                anchor='right'
                classes={{paper:classes.drawer}}
                onClose={()=>onUpdateDrawerStatus(false)}
            >
                <div className={classes.container}>
                    <div className={classes.logo_container} onClick={()=>onChangeView('/')}>
                        <img src={content.logo} alt='' className={classes.logo}/>
                    </div>
                    
                    <div className={classes.menu}>
                        <Grid container>
                            {content.titles.map((item,key)=>{

                                const isactive = isURLSelected(item.url)

                                return(
                                    <Grid item xs={12} key={key.toString()}>
                                        <div className={cx({[classes.topbar_item]:true, [classes.topbar_item_active]:isactive})} onClick={()=>onChangeView(item.url)}>
                                            <Typography variant='caption'>{item.title}</Typography>
                                        </div>
                                    </Grid>
                                )
                            })} 
                        </Grid>
                        
                    </div>
                    <div className={classes.language_container}>
                        <LanguageMenu language={language} onChangeLanguage={onChangeLanguage}/>
                    </div>
                </div>
                
            </Drawer>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    drawer:{
        background:'#1C1C1C',
        width:200,
        //borderTopRightRadius:24,
        //borderBottomRightRadius:24,
        //border:0,
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    logo:{
        width:100
    },
    logo_container:{
        display:'flex',
        justifyContent:'center'
    }, 
    container:{
        //width:150,
        padding:'32px 0px'
    },
    title:{
        color:'white',
        marginTop:8
    },
    menu:{
        margin:'32px 0px'
    },
    topbar_item:{
        padding:'8px 12px',
        borderLeft:'1px solid white',
        color:'white',
        textTransform:'uppercase',
        cursor:'pointer',
        //marginTop:8,
        marginLeft:32,

    },
    topbar_item_active:{
        color:'#5F5F5F',
        borderLeft:'1px solid #5F5F5F',
    }, 
    language_container:{
        //textAlign:'center',
        //marginTop:0,
        //background:'red',
        display:'flex',
        justifyContent:'center'
    }
}))

const mapStateToProps = state => {
    return {
        drawer_status:state.drawer_status,
        language:state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));


const SidebarItem = ({active, name, path, icon, onChangeView}) => {

    const classes = useStylesItem()

    return(
        <div className={cx(classes.root,
        {[classes.active]:active
        })} onClick={() => onChangeView(path)}>
            <Grid container justifyContent='center'>
                <Grid item>
                    <Icon>{icon}</Icon>
                </Grid>
                <Grid item xs={12}>
                    <Typography align='center' variant='body2'>{name}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

const useStylesItem = makeStyles(theme => ({
    root:{
        color:grey[300],
        padding:'16px 8px',
        '&:hover':{
            background:alpha('#FFF',0.2),
            cursor:'pointer',
            transition: theme.transitions.create(['background'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        
    },
    active:{
        background:theme.palette.secondary.main,
        '&:hover':{
            background:theme.palette.secondary.main,
            cursor:'pointer'
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }
}))