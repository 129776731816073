import { useState, useEffect } from "react"
import { private_server } from "../../config"
import { isFormValid, onGetCurrentUserProfile, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../shared/utility"
import { generic_errors } from "../../texts/esp/genericErrors"

const useContactView = ({ language, content}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    useEffect(() => {
        actions.onInitModule()
    }, [])
    
    const actions = {
        onInitModule: async() => {
            const _form = onInitForm(form, null,  content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onSubmit: async() => {

            const errors = isFormValid(form)

            if(errors && errors.length){
                console.log(errors)
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)
            console.log(data2send)

            setSending(true)


            setSending(false)

            let temp =  JSON.parse(JSON.stringify(form_data))
            const _form = onInitForm(temp, null,  content.form)
            setForm(_form)
            /* setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch('/users/me', data2send)
                const _user = await onGetCurrentUserProfile()
                onUpdateUserData(_user)

            } catch (error) {
                console.log(error)
            }
            setLoading(false) */
        }
    }

    const system = {loading, sending, error}

    return {system, form, actions}
}

export default useContactView

const form_data = {
    name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'name',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    company:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'company',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    email: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
        },
        rules: {type: 'email'}
    },
    phone:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'phone',
            type:'number',
            fullWidth: true,
        },
        rules:{
            type:'phone_number',
        }
    },
    message:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'message',
            type:'text',
            fullWidth: true,
            multiline:true,
            rows:'10'
        },
        rules:{
            type:'distance',
            min:1,
            max:500
        }
    },
}
