import carrusel from '../../assets/home_view/carrusel.png'
import home2 from '../../assets/home_view/home2.png'
import home3 from '../../assets/home_view/home3.png'
import map from '../../assets/contact/map.jpg'


export const translations = {
    contact_info:'Tel. +52 (55) 5292 9015 info@martens.com.mx',
    address:'Carlos Graef Fernández No. 222, Torre 1 Piso 6, Santa Fe, 05348, Ciudad de México.',
    image:map,
    form:{
        name:{placeholder:'Name', label:'Name', helperText:'Requiered field'},
        company:{placeholder:'Company', label:'Company', helperText:'Requiered field'},
        email:{placeholder:'Email', label:'Email', helperText:'Email not valid'},
        phone:{placeholder:'Phone number', label:'Phone number', helperText:'Phone number not valid'},
        message:{placeholder:'Body', label:'Body', helperText:'Requiered field'},
        button:'Send'
    }
    
}

export default translations