import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import MemberList from './MemberList';
import MemberToolbar from './MemberToolbar';
import ReactMarkdown from 'react-markdown'
import MemberListMobile from './MemberListMobile';

const content_keys = ['overview','education_experience','academy','colleges', 'testimonials']


const TeamContainer = ({view_data, actions, content}) => {
    const classes = useStyles()
    const selected_key = content_keys[view_data.view-1]

    console.log(view_data.selected)
    
    const richText = view_data.selected ? view_data.selected[selected_key] : null
    let employeeContent =  <Typography align='center' style={{fontSize:16}}>{content.noinfo}</Typography>
    let menu_buttons = [...content.toolbar]
    if(view_data.selected){
        //console.log(view_data.selected)
        for(let i=0; i<menu_buttons.length;i++){
            const val = view_data.selected[menu_buttons[i].key]
            menu_buttons[i].active = val !== null && val !== undefined && val !== ''
            
             //
        }
    }
    /* if(view_data.selected && richText){
        //console.log(view_data)
        employeeContent = <ReactMarkdown children={richText} escapeHtml={false}  />
    } */

    let overView= ''

    //mios
    if(view_data.selected ){
        console.log(view_data.selected.overview)
        overView = <Typography style={{fontFamily:'alda', fontWeight:500}} >{view_data.selected.overview}</Typography>
        employeeContent = <div>
                {view_data.selected.education_experience ? <InfoDisplayer title={content.toolbar[1].label} body={view_data.selected.education_experience} /> : null}
                {view_data.selected.academy ? <InfoDisplayer title={content.toolbar[2].label} body={view_data.selected.academy} /> : null}
                {view_data.selected.colleges ? <InfoDisplayer title={content.toolbar[3].label} body={view_data.selected.colleges} /> : null}
                {view_data.selected.testimonials ? <InfoDisplayer title={content.toolbar[4].label} body={view_data.selected.testimonials} /> : null}
            </div>
    }

    


    
    

    

    return ( 
        <div>
            <Grid container>
                <Grid item xs={12} md={5}>
                    <Hidden smDown>
                        <MemberList employees={view_data.employees} selected={view_data.selected}
                        onChange={actions.onChangeEmployee}/>
                    </Hidden>
                    <Hidden mdUp>
                        <MemberListMobile employees={view_data.employees} selected={view_data.selected}
                        onChange={actions.onChangeEmployee}/>
                    </Hidden>
                </Grid>
                <Grid item xs={12} md={7}>
                    {/* <MemberToolbar view={view_data.view} menu_buttons={menu_buttons} content={content} onChangeView={actions.onChangeDataEmployeeView}
                    onGo2NextEmployee={actions.onGo2NextEmployee}/> */}
                    <div className={classes.overview}>{overView}</div>                  
                    <div className={classes.new_content}>                   
                        {employeeContent}
                    </div>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    content:{
        marginTop:40,
        fontSize:16,
        fontFamily:theme.typography.fontFamily,
        fontWeight:300
    },
    new_content:{
        borderTop:'2px solid #084EB6',
        paddingTop:20,
        fontSize:16,
        fontFamily:theme.typography.fontFamily,
        fontWeight:300
    },
    list_container:{

    },
    overview:{
        paddingBottom:20,
        fontStyle:'italic',
        fontFamily:'alda',
        fontWeight:400,
        fontSize:18,
        textAlign:'justify'
    }

}))
 
export default TeamContainer;

const InfoDisplayer = ({title, body}) => {
    return(
        <div>
            <Typography style={{color:'#75ABFF'}}>{title}</Typography>
            <ReactMarkdown children={body} escapeHtml={false}  />
        </div>
    )
}