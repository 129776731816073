import practices1 from '../../assets/practices1.png'
import practices2 from '../../assets/practices/image-constitutional.jpg'
import practices3 from '../../assets/practices/administrative-image.jpg'
import generalPattern from '../../assets/practices/pattern.svg'
import constitutionalPattern from '../../assets/practices/pattern-constitutional.svg'
import administrativePattern from '../../assets/practices/pattern-administrative.svg'
import mainImage from '../../assets/practices/main.jpg'
import mainPattern from '../../assets/practices/pattern-main.svg'
import decorationPattern from '../../assets/practices/decoration.svg'
import pattern from '../../assets/practices/pattern.svg'
import admin_const from '../../assets/practices/admin_const.png'
import civil_mer_ar from '../../assets/practices/civil_mer_ar.png'


export const translations = {
    main_image:mainImage,
    main_pattern:mainPattern,
    decoration:decorationPattern,
    menu:[{id:1, label:'Litigio Administrativo y Constitucional'}, {id:2, label:'Litigio civil, mercantil, y arbitraje'}],
    civil:{
        title:'Litigio Civil & Mercantil',
        paragraph1:'Atendemos controversias empresariales que involucran intereses relevantes. Analizamos meticulosamente cada detalle y alternativa de los conflictos que nuestros clientes nos encomiendan, ya sea como actores, demandados o terceros. Desarrollamos e implementamos estrategias eficaces e inteligentes que generan resultados exitosos para nuestros clientes ante Tribunales locales y federales a lo largo del país.',
        paragraph2:'Representamos vigorosamente a nuestros clientes en negociaciones con contrapartes hostiles.  Identificamos y empleamos todos los mecanismos jurídicos a disposición de nuestros clientes para defenderlos eficazmente en litigios especialmente complejos de cuyo éxito depende la buena marcha e incluso la subsistencia del negocio.',
        paragraph3:'Tenemos amplia experiencia en la atención de medidas previas al proceso judicial principal tales como providencias precautorias y medidas de aseguramiento, en las cuales hemos logrado precedentes relevantes para nuestros clientes y el orden jurídico mexicano.',
        paragraph4:'Hemos desarrollado una sólida práctica consistente en asumir la representación de nuestros clientes a partir de la segunda instancia o incluso en amparo, logrando subsanar deficiencias sustantivas y procesales, obteniendo resultados exitosos en asuntos que parecían perdidos.',
        paragraph5:{
            text:'Nuestra experiencia reciente comprende:',
            points:[
                'Litigios de responsabilidad civil contractual y extracontractual (daños y perjuicios, incumplimientos, penas convencionales);',
                'Litigios en que se debate la eficacia de resoluciones adoptadas por órganos corporativos;',
                'Litigios entre accionistas de entidades mercantiles, públicas y privadas;',
                'Litigios en que se debate, en todo o en parte, la eficacia de actos civiles y mercantiles y sus efectos;',
                'Procedimientos judiciales en apoyo a arbitraje;',
                'Cobranza civil y mercantil.'
            ]
        },
        image:practices1,
        pattern:generalPattern,
        imagePattern:constitutionalPattern
    },
    constitutional:{
        title:'Amparo y Litigio Constitucional',
        paragraph111:'Hemos atendido exitosamente cientos de juicios de amparo en contra de todo tipo de actos de autoridades federales y locales a lo largo del país.  Entendemos como pocos despachos en México, cada una de las minucias procesales de este proceso constitucional complejo.  La naturaleza de nuestros argumentos y planteamientos nos conduce frecuentemente ante la Suprema Corte de Justicia de la Nación, órgano ante el cual hemos logrado un importante número de sentencias favorables y criterios judiciales relevantes para nuestros clientes y para el sistema jurídico mexicano.',
        paragraph1:'Tenemos amplia experiencia en el desarrollo de demandas de amparo sólidas, en el control exhaustivo y preciso del expediente judicial, en el ofrecimiento y desahogo de pruebas de alta complejidad técnica, en la interposición y atención de incidentes y recursos, y en el debido cumplimiento y ejecución de sentencias de amparo favorables para los intereses de nuestros clientes.',
        paragraph2:'Hemos tenido una participación de liderazgo activo los litigios constitucionales de mayor relevancia en el México contemporáneo.  Asesoramos a cámaras y organizaciones empresariales, colegios y despachos de abogados, empresas del mayor prestigio internacional y nacional, en el desarrollo de estrategias integrales de defensa que involucran juicios de amparo de cuyo éxito depende la operación y subsistencia de empresas y sectores económicos completos.',
        paragraph3:'Defendemos vigorosamente los derechos fundamentales de nuestros representados mediante el desarrollo de estrategias integrales de defensa que exigen planeación y ejecución impecables.',
        image:practices2,
        pattern:generalPattern
    },
    administrative:{
        title:'Litigio Administrativo',
        paragraph1:'Tenemos amplia experiencia en la defensa y representación de empresas frente a actos y omisiones administrativas que afectan sus derechos e intereses.  Hemos impugnado exitosamente innumerables actos de autoridades federales, estatales y municipales.  Somos expertos en el diseño e implementación de estrategias multidisciplinarias e integrales, frente a actos que suscitan contingencias altamente sensibles y crisis corporativas.',
        paragraph2:'Nuestros clientes generalmente se desarrollan en sectores altamente regulados, por lo que hemos acumulado amplia y exitosa experiencia litigando ante y en contra de autoridades de competencia económica, energéticas, ambientales, financieras, y fiscales.',
        paragraph3:'Asimismo, desarrollamos e implementamos litigios estratégicos para hacer frente a restricciones, impedimentos y distorsiones que de alguna manera entorpecen o impiden la adecuada operación de nuestros clientes.  Una y otra vez hemos obtenido resultados favorables de alto impacto individual y general.',
        image:practices3,
        pattern:generalPattern,
        imagePattern:administrativePattern
    },
    admin_constitutional:{
        pattern:pattern,
        image:admin_const,
        title:'Litigio Administrativo y Constitucional',
        intro:'Tenemos amplia experiencia en la defensa y representación de empresas frente a actos y omisiones de autoridades que afectan sus derechos, intereses y legítimas expectativas. Hemos impugnado exitosamente, a lo largo del país, innumerables actos de autoridades federales, estatales y municipales. Somos expertos en el diseño e implementación de estrategias multidisciplinarias e integrales frente a actos que suscitan contingencias altamente sensibles y crisis corporativas.',
        paragraph1:'Nuestros clientes generalmente se desarrollan en sectores altamente regulados por lo que hemos acumulado amplia y exitosa experiencia litigando ante y en contra de todo tipo de autoridades públicas, en muy diversos sectores. Desarrollamos e implementamos litigios estratégicos para hacer frente a restricciones, impedimentos y distorsiones que de alguna manera entorpecen o impiden la adecuada operación de nuestros clientes. Una y otra vez hemos obtenido resultados favorables de alto impacto individual y colectivo',
        paragraph2:'Tenemos amplia experiencia en el desarrollo de planteamientos creativos y sólidos, en el control exhaustivo y preciso del expediente judicial, en el ofrecimiento y desahogo de pruebas de alta complejidad técnica, en la interposición y atención de incidentes y recursos, y en el debido cumplimiento y ejecución de sentencias favorables a los intereses de nuestros clientes.',
        paragraph3:'Hemos tenido una participación de liderazgo activo en los litigios constitucionales de mayor relevancia en el México contemporáneo. Asesoramos a cámaras y organizaciones empresariales, colegios y despachos de abogados, empresas del mayor prestigio internacional y nacional, en el desarrollo de estrategias integrales de defensa que involucran juicios de amparo cuyo éxito depende la operación y subsistencia de empresas y sectores económicos completos.',
        paragraph4:'La naturaleza de nuestros argumentos y planteamientos nos conduce frecuentemente ante la Suprema Corte de Justicia de la Nación, órgano ante el cual hemos logrado un importante número de sentencias favorables y criterios judiciales relevantes para nuestros clientes y para el sistema jurídico mexicano.'
    },
    civil_mercantil_ar:{
        pattern:pattern,
        image:civil_mer_ar,
        title:'Litigio civil, mercantil, y arbitraje ',
        intro:'Atendemos controversias civiles y mercantiles, así como procedimientos arbitrales, que involucran intereses relevantes. Analizamos meticulosamente cada detalle y alternativa de los conflictos que nuestros clientes nos encomiendan, ya sea como actores, demandados o terceros.  Desarrollamos e implementamos estrategias eficaces e inteligentes que generan resultados exitosos para nuestros clientes ante tribunales locales y federales a lo largo del país.',
        paragraph1:'Representamos vigorosamente a nuestros clientes en negociaciones con contrapartes hostiles. Identificamos y empleamos todos los mecanismos jurídicos a disposición de nuestros clientes para defenderlos eficazmente en litigios especialmente complejos de cuyo éxito depende la buena marcha e incluso la subsistencia del negocio.',
        paragraph2:'Tenemos amplia experiencia en la atención de medidas previas al proceso judicial principal tales como providencias precautorias y medidas de aseguramiento, en las cuales hemos logrado precedentes relevantes para nuestros clientes y el orden jurídico mexicano.',
        paragraph3:'Hemos desarrollado una sólida práctica consistente en asumir la representación de nuestros clientes a partir de la segunda instancia o incluso en amparo, logrando subsanar deficiencias sustantivas y procesales, obteniendo resultados exitosos en asuntos que parecían perdidos.',
        paragraph4:'Nuestra experiencia reciente comprende litigios de responsabilidad civil contractual y extracontractual (daños y perjuicios, incumplimientos, penas convencionales); litigios en que se debate la eficacia de resoluciones adoptadas por órganos corporativos;litigios entre accionistas de entidades mercantiles, públicas y privadas; litigios en que se debate, en todo o en parte , la eficacia de actos civiles y mercantiles y sus efectos; arbitrajes nacionales e internacionales, así como procedimientos judiciales en apoyo a estos, entre otros.'
    }
    
}

export default translations