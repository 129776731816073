import logo from '../../assets/logo_martens.png'

/// Celudoc searchbar icons
import calendar_icon from '../../assets/icons/calendar.svg'
import timer_icon from '../../assets/icons/clock.svg'
import moment from 'moment'

import logoTextIcon from '../../assets/logo-text.svg'

// Social media icons
import facebook_icon from '../../assets/icons/facebook.png'
import instagram_icon from '../../assets/icons/instagram.png'
import twitter_icon from '../../assets/icons/twitter.png'



const general_translations = {
    sidebar:{
        logo:logo,
        title:'Monitoring System',
        list:[
            {
                name:'Dashboard',
                path:'/',
                icon:'dashboard',
            },
            {
                name:'Devices',
                path:'/devices',
                icon:'device_hub',
            },
            {
                name:'Locations',
                path:'/locations',
                icon:'room',
            }
        ]
    },
    topbar:{
        titles:[
            {
                url:'/office',
                title:'The Firm'
            },
            {
                url:'/team',
                title:'Team'
            },
            {
                url:'/practices',
                title:'Practices'
            },
            {
                url:'/values',
                title:'principles'
            },           
            {
                url:'/testimony',
                title:'Awards and Testimonials'
            },
            {
                url:'/news',
                title:'News'
            },
            {
                url:'/contact',
                title:'Contact'
            },
        ],
        logo:logo,
        cart_button:{
            remove:'Eliminar',
            subtotal:'Subtotal',
            view_button:'Ver carrito',
            pay_button:'Pagar'
        },
        user_button:{
            nouser:[
                {id:1, label:'Iniciar sesión', url:'/signin'},
                {id:2, label:'Regístrate', url:'/signup'},
                {id:3, label:'Hazte un celudoc', url:'/', top_line:true},
                {id:4, label:'Nuestras membresías', url:'/'},
                {id:5, label:'Ayuda', url:'/', top_line:true},
                {id:6, label:'Contáctanos', url:'/'},
            ],
            user:[
                {id:1, label:'Consultas', url:'/account/meetings'},
                {id:2, label:'Pedidos', url:'/account/my-orders'},
                {id:3, label:'Historia clínica', url:'/',},
                {id:4, label:'Recordatorios', url:'/'},
                {id:5, label:'Cuenta', url:'/account', top_line:true},
                {id:6, label:'Celupuntos', url:'/'},
                {id:7, label:'Ayuda', url:'/', top_line:true},
                {id:8, label:'Contáctanos', url:'/'},
                {id:9, label:'Cerrar sesión', url:'/logout'},
            ]
        },
        searchbar_menu:[
            {id:1, label:'Celufarma'},
            {id:2, label:'Celudoc'},
            {id:3, label:'Celuhistoria'},
        ],
        searchbar:{
            placeholder:'¿Cómo te podemos ayudar?',
            placeholder_mobile:'Buscar...',
            primary_menu:[
                {id:1, label:'Celufarma'},
                {id:2, label:'Celudoc'},
                {id:3, label:'Celuhistoria'},
            ],
            celudoc:{
                speciality_placeholder:'Especialidad',
                date_placeholder:'¿Cuándo?',
                date_options:[
                    {id:1, label:'Lo antes posible', icon:timer_icon},
                    {id:2, label:'Escoge una fecha', icon:calendar_icon},
                ],
                specialities_options:[
                    {id:1, label:'Dermatología'},
                    {id:2, label:'Medicina General'},
                    {id:3, label:'Nutrición'},
                    {id:4, label:'Psicología'},
                ],
                appointment:{
                    labels:{
                        date:'Fecha',
                        time:'Hora'
                    }
                }
            },
            celupharmacy:{
                placeholder:'Especialidad',
                speciality_options:[
                    {id:1, label:'Dermatología'},
                    {id:2, label:'Medicina General'},
                    {id:3, label:'Nutrición'},
                    {id:4, label:'Psicología'}
                ]
            }
        },
    },
    footer:{
        links:[
            {id:1, label:'Celufarma', link:'/'},
            {id:2, label:'Celudoc', link:'/'},
            {id:3, label:'Celuhistoria', link:'/'},
        ],
        legal_links:[
            {id:2, label:`Aviso de Privacidad ${moment().format('YYYY')}`, url:'/privacy', target:'_blank'},
            {id:1, label:'Términos y condiciones', url:'/terms-conditions', target:'_blank'},
        ],
        logo:logoTextIcon,
        social_media:{
            title:'Contáctanos',
            media:[
                {id:1, icon:facebook_icon, url:'/', target:'_blank'},
                {id:2, icon:instagram_icon, url:'/', target:'_blank'},
                {id:3, icon:twitter_icon, url:'/', target:'_blank'},
            ],
        }
    },
    
}

export default general_translations