import carrusel from '../../assets/home_view/carrusel.png'
import home2 from '../../assets/home_view/home2.png'
import home3 from '../../assets/home_view/home3.png'
import map from '../../assets/contact/map.jpg'


export const translations = {
    contact_info:'Tel. +52 (55) 5292 9015 info@martens.com.mx',
    address:'Carlos Graef Fernández No. 222, Torre 1 Piso 6, Santa Fe, 05348, Ciudad de México.',
    image:map,
    form:{
        name:{placeholder:'Nombre', label:'Nombre', helperText:'Campo requerido'},
        company:{placeholder:'Compañía', label:'Compañía', helperText:'Campo requerido'},
        email:{placeholder:'Correo electrónico', label:'Correo electrónico', helperText:'Correo no válido'},
        phone:{placeholder:'Teléfono', label:'Teléfono', helperText:'Número de teléfono no válido'},
        message:{placeholder:'Mensaje', label:'Mensaje', helperText:'Campo requerido'},
        button:'Enviar'
    }
    
}

export default translations