import carrusel from '../../assets/home_view/carrusel.png'
import bannerA from '../../assets/banners/bannerA.jpg'
import bannerB from '../../assets/banners/bannerB.jpg'
import bannerC from '../../assets/banners/bannerC.jpg'


import home2 from '../../assets/home_view/home2.png'
import home3 from '../../assets/home_view/home3v2.jpg'


export const translations = {
    left_side:{
        text:'Somos un despacho único en México, dedicado exclusivamente a la atención de',
        highlight_text:'controversias complejas.'
    },
    right_side:{
        title:'El arte del litigio.',
        paragraph1:'Cada asunto que el despacho decide atender es atendido al límite de las capacidades de un equipo de profesionales de excelencia. Nos apasiona lo que hacemos y tenemos clara nuestra misión: darles a nuestros clientes el mejor servicio jurídico especializado en litigio disponible en México.',
        paragraph2:'Brindamos soluciones creativas e innovadoras a los problemas más sensibles y complejos que nuestros clientes enfrentan, proporcionando resultados excepcionales.',
        
    },
    image1:home2,
    image2:home3,
    main_image:bannerA,
    carrousel:[
        {id:1, img:bannerA},
        {id:2, img:bannerB},
        {id:3, img:bannerC},
    ]
    
}

export default translations