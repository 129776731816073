import comillas from '../../assets/testimony/icon.svg'

import firm from '../../assets/testimony/logos/firm.jpeg'
import generation from '../../assets/testimony/logos/generation.jpeg'
import lawyer from '../../assets/testimony/logos/lawyer.jpeg'
import logo from '../../assets/testimony/logos/Logo.png'
import logo_chambers from '../../assets/testimony/logos/LogoChambers.jpeg'

export const translations = {
    left_side:{
        text:'The most prestigious international publications that research legal markets, have independently gathered the following opinions from some of our clients:  ',
    },
    right_side:{
        icon:comillas,
        testimonys:[
            '“They are specialists in litigation, understand their clients’ needs perfectly and approach the problems with innovative techniques.”',
            '“Great service, quick attention and excellent preparation. They are a very proactive team and know the matter. They deliver executive and punctual reports of the proceedings. They are very proactive and provide opinions with legal basis.  They try to know in detail the operation of the company and its sector, which is important.”',
            '“They are able to provide a real sensitivity to the different strategies and approaches to successful litigation and complement the claims with studies that effectively help the courts to understand the case and its impact.”',
            '“They are always able to explain things in a simple way which reinforces the confidence and decision making of companies.”',
            '“The team has deep knowledge of the Mexican laws and has a strategic perspective to handle litigation proceedings.  Before commencing any judicial proceeding, they carry out a thorough analysis of the matter, anticipating any contingency that may arise in its development.  They follow punctually the judicial proceedings, promoting the processes in all the stages.  We recommend them for: 1. Availability. 2.- Ease of communications. 3.- Adaption to the client’s needs.”',
            '“Personalized, direct, punctual and transparent service. Competitive prices and great imagination to propose suitable legal strategies. They provide great confidence.  They communicate the development of the matters punctually, as well as the results that might arise on the dispute. They are very committed and take the cases of their clients as if they were of them.”',
            '“It is a firm that understands the client’s needs in litigation and provides confidence to defend the interests of the company in courts. It is an exceptional firm in response time and transparency to provide a legal strategy to follow before court.”  '
        ]
        
    },
    logos:[
        firm, logo, logo_chambers, generation, lawyer
    ]
  
    
}

export default translations