import { Grid, Hidden, Icon, makeStyles, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import system_translations from "../../texts/system_translations"
import ValuesDesktop from './responsive/ValuesDesktop';
import ValuesMobile from './responsive/ValuesMobile';

const ValuesView = ({language}) => {

    const classes = useStyles()
    const content = system_translations[language].views.values

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return ( 
        <div  >
            <ValuesDesktop content={content} />
            {/**
             
             <Hidden mdUp>
                <ValuesMobile content={content} />
            </Hidden>
            <Hidden smDown>
                <ValuesDesktop content={content} />
            </Hidden>
             * 
             */}
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 
export default connect(mapStateToProps)(ValuesView) ;

const useStyles = makeStyles(theme => ({
    root:{
        padding:'100px 0px 0px',
        background:'#1C1C1C',  
        //color:'#F4F4F4',
    },
    subroot:{
        width:1200,
        color:'white',
        margin:'auto'
    },
    bottom_rectangle:{
        width:'100%',
        height:50,
        background:'#303030',
        marginTop:200
    },
    header:{
        padding:'20px 0px',
        textTransform:'uppercase'
    },
    text_column2:{
        padding:'0px 20px'
    },
    first_row:{
        height:280,
        width:'100%',
        position:'relative',
        //background:'white'
    },
    second_row:{
        height:220,
        width:'100%',
        position:'relative',
        //background:'green'
    },
    third_row:{
        height:180,
        width:'100%',
        position:'relative',
        margin:'40px 0px 100px',
        //background:'yellow'
    },
    fourth_row:{
        height:270,
        width:'100%',
        position:'relative',
        //background:'white'
    },
    fifth_row:{
        //height:180,
        width:'100%',
        position:'relative',
        //background:'white'
    },
    deco1:{
        width:'33%',
        height:'100%',
        background:'repeating-linear-gradient(80deg, transparent, transparent 3%, #084EB6 4%)'
    },
    deco2:{
        position:'absolute',
        left:40,
        width:'80%',
        height:'90%',
        background:'repeating-linear-gradient(70deg, transparent, transparent 2%, #C4C4C4 3%)'
    },
    deco3:{
        position:'absolute',
        bottom:0,
        width:'100%',
        height:'60%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 2%, #084EB6 3%)'
    },
    deco4:{
        position:'absolute',
        top:'50%',
        width:'100%',
        height:'50%',
        background:'repeating-linear-gradient(45deg, transparent, transparent 3%, #5F5F5F 4%)'
    },
    deco5:{
        position:'absolute',
        bottom:0,
        width:'50%',
        height:'100%',
        background:'repeating-linear-gradient(-70deg, transparent, transparent 2%, #084EB6 3%)'
    },
    deco6:{
        position:'absolute',
        bottom:'5%',
        width:'80%',
        height:'60%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 3%, #5F5F5F 4%)'
    },
    deco7:{
        width:'40%',
        height:'100%',
        background:'repeating-linear-gradient(80deg, transparent, transparent 2%, #C4C4C4 3%)',
    },
    deco8:{
        position:'absolute',
        bottom:0,
        width:'70%',
        height:'80%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 2%, #084EB6 3%)'
    },
    container01:{
        color:'#184EA2',
        position:'absolute',
        bottom:'10%',
        left:'15%',
    },
    container02:{
        color:'#1C1C1C',
        position:'absolute',
        bottom:'15%',
        left:'30%',
    },
    container03:{
        //color:'#1C1C1C',
        position:'absolute',
        bottom:12,
        left:4,
        display:'flex'
    },
    container04:{
        color:'#5F5F5F',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    },
    container05:{
        color:'#5F5F5F',
        position:'absolute',
        top:'50%',
        left:0,
        transform:'translateY(-50%)'
    },
    container06:{
        color:'#5F5F5F',
        position:'absolute',
        left:'50%',
        bottom:-28,
        transform:'translateX(-50%)',
        '&:webkitTextStroke':'2px black'
    },
    container07:{
        color:'#184EA2',
        position:'absolute',
        left:'50%',
        bottom:-28,
        transform:'translateX(-50%)',
    },
    container08:{
        color:'#184EA2',
        position:'absolute',
        left:0,
        bottom:-28,
        //transform:'translateX(-50%)',
    },
    
    
}))