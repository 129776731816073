import React, { useState } from 'react';
import { alpha, Grid, Hidden, Icon, makeStyles, Slide, Typography } from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';

const AdministrativeSection = ({content}) => {

    const classes = useStyles()

    const [section_dispatcher, setSectionDispatcher] = useState({
        image:false,
    })

    const onUpdateDispatcher = (data) => {
        setSectionDispatcher({...section_dispatcher, ...data})
    }

    return ( 
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h6' className={classes.title}>
                    {content.title}
                </Typography>
                <div className={classes.pattern_container}>
                    <img alt='' className={classes.pattern} src={content.pattern}/>
                </div>
            </div>
            <div className={classes.overview}>
                {content.paragraph1}
            </div>
            <div>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <InViewMonitor onInView={()=> onUpdateDispatcher({image:true})} >
                            <div className={classes.imagePattern}>
                                <Slide in={section_dispatcher.image} timeout={2000} direction='right' >
                                    <div>
                                        <img src={content.image}  className={classes.imageBackground}/>
                                    </div>
                                </Slide>
                                <Slide in={section_dispatcher.image} timeout={2000} direction='right' >
                                    <div>
                                        <img src={content.imagePattern}  className={classes.imageLine}/>
                                    </div>
                                </Slide>
                                
                            </div>
                        </InViewMonitor>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.list_wrapper}>
                            <Typography className={classes.paragraph}>
                                {content.paragraph2}
                            </Typography>
                            <Typography className={classes.paragraph}>
                                {content.paragraph3}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        width:800,
        marginBottom:120,
        [theme.breakpoints.down('md')]:{
            width:'100%',
        },
    },
    header:{
        display:'flex',
        flexWrap:'no-wrap',
        alignItems:'center'
    },
    title:{
        width:220,
        fontFamily:theme.typography.fontFamily,
        //border:'1px solid white'
    },
    pattern_container:{
        flex:1,
        marginLeft:40
    },
    pattern:{
        width:'100%'
    },
    overview:{
        margin:'42px 0px',
        borderTop:'1px solid white',
        borderBottom:'1px solid white',
        fontFamily:'alda',
        fontSize:18,
        padding:'20px 0px',
        fontStyle:'italic'
        
    },
    paragraph:{
        marginBottom:16,
        fontSize:16
    },
    list_wrapper:{
        marginLeft:54,
        marginRight:16
    },
    list:{
        fontSize:16,
        fontFamily:theme.typography.fontFamily,
        fontWeight:300
    },
    list_item:{
        marginBottom:16
    },
    imagePattern:{
        position:'relative',
        //marginTop:32,
        height:250,
        //border:'1px solid white'
    },
    imageBackground:{
        position:'absolute',
        top:0, left:-200,
        zIndex:2,
        width:567,
        [theme.breakpoints.down('md')]:{
            width:'100%',
            left:-0,
        },
    },
    imageLine:{
        position:'absolute',
        top:93, left:-435,
        width:466,
        //background:alpha('#000',0.25),
        zIndex:4,
        height:176,
        [theme.breakpoints.down('md')]:{
            width:'100%',
            left:-40,
            top:50
        },
    }

}))
 
export default AdministrativeSection;

