import { CircularProgress, Fade, Grid, Hidden, makeStyles, Slide, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import system_translations from "../../texts/system_translations"
import useTeamView from './useTeamView';
import TeamContainer from './components/TeamContainer';

const TeamView = ({language}) => {

    const classes = useStyles()
    const content = system_translations[language].views.team
    const {system, view_data, actions} = useTeamView({language, content})

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    
    return ( 
        <div className={classes.root} >

            <div className={classes.left}>
                <Fade in timeout={2000}>
                    <Typography className={classes.text}>
                        {content.textContent}
                    </Typography>
                </Fade>
                <div className={classes.pattern1Container}>
                        
                    <div className={classes.small_image_container}>
                        <Slide in timeout={1500} direction='right' >
                            <div>
                                <img src={content.small_image} className={classes.small_image}/>
                            </div>
                        </Slide>
                    </div>
                    <Hidden smDown>
                        <div className={classes.black_pattern_container}>
                            <Slide in timeout={1500} direction='right' >
                                <div>
                                    <img src={content.pattern_black} className={classes.black_pattern}/>
                                </div>
                            </Slide>
                            
                        </div>
                    </Hidden>
                    
                </div>
                <div className={classes.patternContainer}>
                        
                    <div className={classes.image_container}>
                        <Slide in timeout={1500} direction='right' >
                            <div>
                                <img src={content.image} className={classes.image}/>
                            </div>
                        </Slide>
                    </div>
                    <Hidden smDown>
                        <div className={classes.pattern_container}>
                            <Slide in timeout={1500} direction='right' >
                                <div>
                                    <img src={content.pattern} className={classes.pattern}/>
                                </div>
                            </Slide>
                            
                        </div>
                    </Hidden>
                </div>
            </div>
            <div className={classes.right}>
                {system.loading ? (
                    <div>
                        <Grid container justifyContent='center' >
                            <Grid item>
                                <CircularProgress color='secondary' size={54} />
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <Fade in timeout={1000}> 
                        <div>
                            <TeamContainer  view_data={view_data} actions={actions} content={content}/>

                        </div>

                    </Fade>
                )}
                
            </div>
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 
export default connect(mapStateToProps)(TeamView) ;

const useStyles = makeStyles(theme => ({
    root:{
        padding:'100px 0px',
        background:'#1C1C1C',  
        color:'#C4C4C4',
        fontSize:'0.8rem',
        //height:'100%'
        display:'flex',
        minHeight:'80vh',
        [theme.breakpoints.down('md')]:{
            flexWrap:'wrap'
        }
    },
    text:{
        fontSize:16,
        fontFamily:'alda',
        fontWeight:400,
        width:310,
        paddingLeft:80,
        fontWeight:300,
        [theme.breakpoints.down('md')]:{
            //paddingLeft:32,
            padding:'0px 16px',
            boxSizing:'border-box',
            width:'100%',
            //marginBottom:100,
        }
    },
    left:{
        flex:1,
        paddingRight:'40px',
        boxSizing:'border-box',
        position:'relative',
        [theme.breakpoints.down('md')]:{
            paddingRight:0,
        }
    },
    right:{
        width:1200,
        padding:'0px 20px',
        paddingRight:80,
        boxSizing:'border-box',
        position:'relative',
        [theme.breakpoints.down('md')]:{
            marginTop:32,
            paddingRight:20,
            //width:'100%',
            ///minWidth:1000,
            ///padding:'0px 40px',
            ///height:'60vh'
         },
    },
    patternContainer:{
        marginTop:40,
        marginLeft:65,
        //border:'1px solid white',
        position:'relative',
        width:'100%',
        paddingTop:'66%',
        [theme.breakpoints.down('md')]:{
            //paddingTop:'40%',
            padding:0,
            margin:0,
            boxSizing:'border-box',
            //border:'1px solid white',
            padding:'0px 16px'
            //width:'100%',
            ///minWidth:1000,
            ///padding:'0px 40px',
            ///height:'60vh'
         },
        //background:'red'
    },
    image_container:{
        position:'absolute',
        top:0, 
        right:'-5%',
        width:'100%',
        zIndex:2,
        [theme.breakpoints.down('md')]:{
            position:'relative',
            boxSizing:'border-box',
            top:0, left:0,
        },
    },
    image:{
        width:'70%',
        [theme.breakpoints.down('md')]:{
            width:'100%',
        },
    },
    pattern1Container:{
        marginTop:40,
        marginLeft:65,
        position:'relative',
        width:'100%',
        paddingTop:'40%',
        [theme.breakpoints.down('md')]:{
            boxSizing:'border-box',
            //border:'1px solid blue',
            padding:'16px 16px',
            margin:0
         },
        
    },
    small_image_container:{
        position:'absolute',
        top:0, right:0,
        width:'100%',
        zIndex:2,
        [theme.breakpoints.down('md')]:{
            position:'relative'
         },
    },
    small_image:{
        width:'55%',
        [theme.breakpoints.down('md')]:{
            width:'100%',
         },
    },
    text_container:{
        marginLeft:100,
        maxWidth:300,
        marginBottom:100,
        
    },
    pattern_container:{
        position:'absolute',
        //bottom:'-10%', right:'30%',
        top:'10%',
        right:0,
        width:'100%',
        zIndex:0
    },
    pattern:{
        width:'30%'
    },
    black_pattern_container:{
        position:'absolute',
        //bottom:'-10%', 
        //right:'30%',
        //top:-40,
        top:'-12%',
        right:'30%',
        width:'20%',
        zIndex:2,
        [theme.breakpoints.down('md')]:{
            display:'hidden'
        },
    },
    black_pattern:{
        width:'100%',
        [theme.breakpoints.down('md')]:{
            display:'hidden'
        },
    },
}))