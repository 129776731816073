import blog_asset_1 from '../../assets/blogs/blog-1.png'
import blog_asset_2 from '../../assets/blogs/blog-2.png'
import blog_asset_3 from '../../assets/blogs/blog-3.png'

import office1 from '../../assets/office1.png'
import office2 from '../../assets/office2.png'
import office3 from '../../assets/office3.png'
import columna from '../../assets/roof.jpg'
import pattern from '../../assets/office_pattern.svg'


export const translations = {
    paragraph1:'Litigation law firm specialized in complex business disputes, recognized as one of Mexico’s leading dispute resolution firms by the most prestigious international publications. Our results and history speak for themselves: we are a unique firm that constantly exceeds our clients’ expectations.',
    paragraph2:'We actively participate, with the same level of excellence, in administrative, civil, constitutional and commercial litigation matters, and in domestic and international arbitration proceedings. Within seven years since its foundation, Martens has become the go-to litigation firm when there is no margin of error, when the complexity and sophistication of the dispute demand a flawless strategy and execution, and when it seem that there is no way around a legal challenge.',
    paragraph3:'Unique among its class, Martens is comprised by a solid and diverse group of rigorously trained professionals, and it distinguishes itself by its unique track record and its ability to develop strategies and arguments that end up becoming relevant judicial precedents for the Mexican legal system. ',
    paragraph4:'Our team has deep knowledge of the Mexican legal system and its interpretation, as well as broad judicial experience before federal and local courts. We do not improvise, once that we decide to handle a matter, it is meticulously analysed; we develop different strategies and routes; we anticipate and foresee different scenarios; and each procedural decision is carefully evaluated.',
    main_image:office1,
    medium_image:columna,
    small_image1:office2,
    small_image2:office3,
    pattern:pattern
    
}

export default translations