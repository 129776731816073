import general_es from './esp/general-translations'
import front_end_errors_es from './esp/frontend-erros'
import home_es from './esp/home-translations'
import office_es from './esp/office-translations'
import values_es from './esp/values-translations'
import contact_es from './esp/contact-translations'
import practices_es from './esp/practices-translations'
import team_es from './esp/team-translations'
import articles_es from './esp/articles-translations'
import article_es from './esp/article-translations'
import testimony_es from './esp/testimony-translations'

// ENGLISH
import general_en from './eng/general-translations'
import front_end_errors_en from './eng/frontend-erros'
import home_en from './eng/home-translations'
import office_en from './eng/office-translations'
import values_en from './eng/values-translations'
import contact_en from './eng/contact-translations'
import practices_en from './eng/practices-translations'
import team_en from './eng/team-translations'
import articles_en from './eng/articles-translations'
import article_en from './eng/article-translations'
import testimony_en from './eng/testimony-translations'


const system_translations = {
    es:{
        errors: front_end_errors_es,
        general:general_es,
        views:{
            home:home_es,
            office:office_es,
            values:values_es,
            contact:contact_es,
            practices:practices_es,
            team:team_es,
            articles:articles_es,
            article:article_es,
            testimony:testimony_es
        },
        modals:{

        },
    },
    en:{
        errors: front_end_errors_en,
        general:general_en,
        views:{
            home:home_en,
            office:office_en,
            values:values_en,
            contact:contact_en,
            practices:practices_en,
            team:team_en,
            articles:articles_en,
            article:article_en,
            testimony:testimony_en
        },
        modals:{

        },
    }
}

export default system_translations