import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import WebsiteRouter from '../../routes/website-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import cx from 'classnames'
import Footer from './components/Footer';
import useWebsiteLayout from './useWebsiteLayout';
import ErrorModal from '../../components/Modals/ErrorModal';
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS } from '../../config';
import Sidebar from './components/Sidebar';
import MainBanner from './components/MainBanner';
import file from '../../assets/privacy_notice.pdf'
import file_en from '../../assets/privacy_notice_en.pdf'

import { grey } from '@material-ui/core/colors';

import ReactGA from 'react-ga'
import { withRouter } from 'react-router';

ReactGA.initialize(CONFIG_SETTINGS.GA_TRACKING_CODE)


const WebsiteLayout = ({history, onUpdateURL, blur, user, is_auth, error, language,
    onUpdateUserData, onUpdateAuthStatus, onUpdateCart, onUpdateError, onUpdateLanguage}) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {loading, sending, actions } = useWebsiteLayout({onUpdateUserData, onUpdateAuthStatus, onUpdateCart, onUpdateLanguage})

        console.log(history.location.pathname)

    useEffect(() => {
        console.log('Observar nueva pagina')
        console.log(window.location.pathname + window.location.search)
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [history.location.pathname])
    

    return ( 
        <div className={cx({
            [classes.root]:true,
            [classes.root_blur]:blur
        })}>
            <ErrorModal open={Boolean(error)} message={error ? error.message : null}
            onClose={() => onUpdateError(null)}/>
            <MainBanner />
            <main className={classes.main} >
                <Topbar ismobile={ismobile} />
                <Sidebar onUpdateLanguage={onUpdateLanguage}/>
                <div className={classes.subroot}>
                    <WebsiteRouter/>
                    <div className={classes.privacy}><a href={language === 'es' ? file : file_en}  target='_blank'  style={{textDecoration:'none', color:grey[400]}} >{language === 'es' ? 'Aviso de privacidad' : 'Privacy Notice'}</a></div>
                </div>
            </main>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    privacy:{
        color:'white',
        position:'absolute',
        bottom:16, left:'50%',
        transform:'translateX(-50%)'
        //background:'white'
    },
    root:{
        //background:'#edf2f8',
        minHeight:'100vh',
        overflowY:'auto',
        filter:'none'

    },
    root_blur:{
        filter:'blur(3px)'
    },
    main:{
        //marginLeft:150,
        //padding:16,
        overflowY:'auto',
        background:'#1C1C1C',
        
    },
    subroot:{
        position:'relative',
        marginTop:DASHBOARD_SETTINGS.TOPBAR.HEIGHT,
        flexGrow: 1,
        
        //padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('sm')]: {
            marginLeft:0
          },
    },
}))

const mapStateToProps = state => {
    return {
        language: state.language,
        is_auth: state.is_auth,
        ip_address:state.ip_address,
        blur: state.blur,
        user: state.user,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateError: (error) => dispatch({type:actionTypes.SYS_UPDATE_ERROR, error}),
        onUpdateLanguage: (language) => dispatch({type:actionTypes.SYS_UPDATE_LANGUAGE, language})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebsiteLayout));

