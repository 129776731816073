import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import NextButton from '../../../assets/icons/next-button.svg'
import { alpha, Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames'
const MemberToolbar = ({content, onChangeView, view, onGo2NextEmployee, menu_buttons}) => {

    const classes = useStyles()

    return ( 
        <div>
            <Grid container>
                <Grid item xs>
                    <Grid container spacing={2}>
                        {menu_buttons.map(item => {
                            if(!item.active) return null
                            return(
                                <Grid item key={`datamenu-${item.id}`}>
                                    <MenuButton {...item} onClick={onChangeView} view={view}/>
                                </Grid>
                            )
                        })}
                        
                    </Grid>
                </Grid>
                <Grid item>
                    <div onClick={onGo2NextEmployee} style={{cursor:'pointer'}}>
                        <img src={NextButton} className={classes.image}/>    
                    </div>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    image:{
        width:157, height:84
    }
}))
 
export default MemberToolbar;


const MenuButton = ({label, id, onClick, view}) => {

    const classes = useMenuButtonStyles()

    const selected = view === id

    return(
        <div className={cx({
            [classes.root]:true,
            [classes.root_selected]:selected
        })} onClick={() => onClick(id)}>
            {label}
        </div>
    )
}

const useMenuButtonStyles = makeStyles(theme => ({
    root:{
        padding:'8px 12px',
        fontFamily:theme.typography.fontFamily,
        fontWeight:300,
        fontSize:14,
        border:`1px solid ${grey[500]}`,
        color:grey[500],
        borderRadius:40,
        cursor:'pointer',
        textTransform:'uppercase',
        transition:'all 0.1s linear',
        '&:hover':{
            color:grey[50],
            border:`1px solid ${grey[50]}`,
            transition:'all 0.4s linear'
            //background:alpha('#FFF',0.1)
        }
    },
    root_selected:{
        border:`1px solid ${theme.palette.secondary.main}`,
        color:'white',
        '&:hover':{
            //color:grey[50],
            border:`1px solid ${theme.palette.secondary.main}`,
            transition:'all 0.4s linear'
            //background:alpha('#FFF',0.1)
        }
    }
}));