import { Button, CircularProgress, Fade, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import system_translations from "../../../texts/system_translations"
import cx from 'classnames';
import useArticlesView from './useArticlesView';
import { onGetMonthName, onTruncateText } from '../../../shared/utility';
import banner from '../../../assets/news/pictureA.jpg'
import pattern from '../../../assets/news/pattern.svg'


const ContactView = ({language, history}) => {

    const classes = useStyles()
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const content = system_translations[language].views.articles
    const {system, view_data, actions} = useArticlesView({language, content})

    return ( 
        <div className={classes.root} >
            <div className={classes.subroot}>
                <div className={classes.image_container}>
                    <div>
                        
                        <img src={pattern} className={classes.pattern} />
                    </div>
                </div>
                
                <Fade in={!system.loading} timeout={1000}>
                    <div className={classes.container}>
                        {system.loading ? (
                        <div style={{flex:1, }}>
                            <Grid container justifyContent='center'>
                                <Grid item >
                                    <CircularProgress color='secondary'/>
                                </Grid>
                            </Grid>
                        </div>
                    )
                    : null}
                        {view_data.articles.map(item => {
                            return(
                                <div className={classes.row}>
                                    <div className={classes.border}></div>
                                    <div className={classes.date_container}>
                                        <div className={classes.vertical_line}/>
                                        <div className={cx({
                                            [classes.year]:true,
                                            [classes.year_hidden]:!item.first_year,
                                        })}>
                                            {item.first_year || mobile ? item.year.toString() : ''}
                                        </div>
                                        {/* <div style={{borderBottom:'1px solid #084EB6'}}> */}
                                            <div className={cx({
                                                [classes.month]:true,
                                                [classes.month_hidden]:!item.first_month,
                                            })}>
                                                <div className={classes.month_text}>
                                                    {item.first_month || mobile ? onGetMonthName(item.month, language) : ''}
                                                </div>
                                            </div>
                                            <div className={classes.day}>
                                                / {item.day}
                                            </div>
                                        {/* </div> */}
                                    </div>
                                    <div className={classes.article_container}>
                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <Typography className={classes.title} >{item.title}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className={classes.horizontal_line}/>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant='body2' className={classes.content}>
                                                    { onTruncateText(item.content.replaceAll('**',''),250)}
                                                    <div  onClick={() => history.push(`/news/${item.id}`)} className={classes.link}>Continuar</div>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Fade>
                
            </div>
            <div className={classes.circle_container}/>
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 
export default connect(mapStateToProps)(ContactView) ;

const useStyles = makeStyles(theme => ({
    root:{
        padding:'100px 0px',
        background:'#1C1C1C',
        paddingBottom:200,  
        //minHeight:'calc(100vh - 400px)',
        color:'#E5E5E5',
        [theme.breakpoints.down('sm')]:{
            padding:'50px 40px',
        },
        fontFamily:theme.typography.fontFamily
    },
    subroot:{
        display:'flex'
    },
    image_container:{
        flex:1,
        minHeight:'80vh',
        position:'relative',
        //overflowX:'hidden'
    },
    banner:{
        top:0,
        //right:100,
        width:289,
        position:'absolute',
        zIndex:2,
    },
    pattern:{
        top:24,
        left:120,
        width:290,
        //height:400,
        position:'absolute',
        zIndex:1,
        [theme.breakpoints.down('lg')]:{
            display:'none'
        }
    },
    container:{
        width:1100,
        paddingRight:80,
        boxSizing:'border-box',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            padding:0,
            //background:'green'
        }
        //border:'1px solid white',
    },
    row:{
        display:'flex',
        //borderBottom:'1px solid #084EB6',
        marginTop:24,
        position:'relative',
        [theme.breakpoints.down('sm')]:{
            display:'inline',
            background:'red'
        }
        //background:'red'
    },
    border:{
        width:'90%',
        background:'#084EB6',
        height:1,
        position:'absolute',
        right:0,
        bottom:0
    },
    title:{
        fontFamily:'Alda OT CEV',
        fontStyle:'italic',
        fontSize:16
    },
    year:{
        width:100,
        marginTop:16,
        fontSize:'1rem', 
        color:'#084EB6',
        //height:'100%',
        //borderBottom:'2px solid #1C1C1C',
    },
    month:{
        //width:50,
        //height:50,
        //border:'1px solid #184EA2',        
        //borderRadius:'50%',     
        fontSize:'0.9rem',
        position:'relative',
        //background:'#1C1C1C'
    },
    month_hidden:{
        border:'1px solid transparent',
        background:'transparent'
    },
    month_text:{
        width:120,
        marginTop:16,
        textTransform:'uppercase',
        //position:'absolute',
        //top:'50%', left:'50%',
        //transform:'translate(-50%,-50%)',
        color:'#E5E5E5'
    },
    day:{
        width:100,
        boxSizing:'border-box',
        //paddingRight:32,
        marginTop:16,
        fontSize:'1rem',
        //textAlign:'right'
    },
    date_container:{
        //border:'1px solid white',
        display:'flex',
        //padding:'0px 8px',
        //paddingLeft:8,
        position:'relative',
        //background:'red',
        [theme.breakpoints.down('sm')]:{
            //background:'red',
            marginBottom:14           
        }
    },
    vertical_line:{
        /* background:'#184EA2',
        height:'100%',
        width:1,
        position:'absolute',
        top:0,
        left:134,
        zIndex:0 */
    },
    article_container:{
        //border:'1px solid white',
        flex:1,
        padding:'0px 8px',
        [theme.breakpoints.down('sm')]:{
            flex:'none',
            //width:'100%'
        }
        //borderBottom:'1px solid #084EB6'
    },
    horizontal_line:{
        background:'#184EA2',
        height:1,
        width:'80%',
        margin:'auto',
        //margin:'16px 0px',
        marginTop:24,
        [theme.breakpoints.down('sm')]:{
            marginTop:12,
            marginBottom:12          
        }
    },
    content:{
        paddingBottom:40,
    },
    link:{
        marginLeft:8,
        textDecoration:'none !important',
        color:'#74a7fa',
        fontStyle:'italic',
        fontWeight:500,
        cursor:'pointer',
        [theme.breakpoints.down('sm')]:{
            marginTop:8
        }

    },
    circle_container:{
        width:'20%',
        paddingTop:'20%',
        //paddingTop:'50%',
        border:'1px solid white',
        position:'absolute',
        zIndex:2,
        bottom:0,
        left:'30%',
        transform:'translate(-40%,40%)',
        zIndex:1,
        background: '#184EA2',
        borderRadius:'50%',
        filter:'blur(100px)'
    },
    
    
}))

