import { CircularProgress, Fade, Grid, Hidden, Icon, makeStyles, Slide, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import system_translations from "../../../texts/system_translations"
import useArticleView from './useArticleView';
import image_pattern from '../../../assets/news/single-new-patter.svg'
import { nodata } from '../../../config';
import ReactMarkdown from 'react-markdown'
import moment from 'moment';
import whiteArrowUp from '../../../assets/news/arrow-up-white.svg'
import whiteArrowDown from '../../../assets/news/arrow-down-white.svg'
import blueArrowUp from '../../../assets/news/arrow-up-blue.svg'
import blueArrowDown from '../../../assets/news/arrow-down-blue.svg'
import rectanglePattern from '../../../assets/news/rectangle-pattern.svg'
import rectangleOutline from '../../../assets/news/rectangle-outline.svg'
import { onGetMonthName } from '../../../shared/utility';
const ArticleView = ({language, match, history}) => {

    const classes = useStyles()
    const content = system_translations[language].views.article
    const {system, view_data, actions} = useArticleView({language, content, match})


    const [section_dispatcher, setSectionDispatcher] = useState({
        constitutional:false,
        administrative:false,
    })

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const onUpdateDispatcher = (data) => {
        setSectionDispatcher({...section_dispatcher, ...data})
    }


    const {article} = view_data


    let year = nodata
    let month = nodata
    let day = nodata

    if(article){
        const temp = moment(article.published_date)
        if(temp.isValid()){
            year = temp.year()
            month = temp.month()+1 < 10 ? `0${temp.month()+1}` : temp.month()+1
            
            day = temp.date()
        }
    }

    
    
    return ( 
        <div className={classes.root} >
            <div className={classes.container}>
                <Hidden smDown>
                    <div className={classes.left_container}>
                        <div className={classes.subcontainer}>
                            <Slide in timeout={1500} direction='right' >
                                <div>
                                    <img src={rectanglePattern} width='80%' />
                                </div>
                            </Slide>
                            <div className={classes.pattern_container}>
                                <Fade in timeout={3000} >
                                    <div><img src={rectangleOutline} width='100%' className={classes.pattern_image}/></div>
                                </Fade>
                                
                            </div>

                            <div className={classes.circle_container}/>

                        </div>
                    </div>
                </Hidden>
                
                <div className={classes.right_container}>

                    {system.loading ? (
                        <div style={{marginTop:40}} >
                            <Grid container justifyContent='center'>
                                <Grid item>
                                    <CircularProgress color='secondary'/>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        <Fade in={!system.loading}>
                            <div >
                                <div className={classes.image_container}>
                                    <img src={(article && article.image) ? article.image.url : null} className={classes.image}/>
                                    <Hidden smDown> <img src={image_pattern} className={classes.image_pattern}/></Hidden>
                                </div>
                                <div className={classes.content_container}>
                                    <div className={classes.return_link} onClick={() => history.goBack()}>
                                        <Icon fontSize='small' >arrow_back_ios_new</Icon>
                                       <i>{content.return}</i> 
                                    </div>
                                    <div className={classes.title}>
                                        
                                        <Grid container alignItems='baseline'>
                                            <Grid item xs>
                                                <Grid container spacing={2} justifyContent='flex-end' alignItems='baseline'>
                                                    <Grid item xs={12} md>
                                                        {article ? article.title : nodata}
                                                    </Grid>
                                                    <Grid item >
                                                        <div className={classes.date_container}>
                                                            <div className={classes.year}>
                                                                {article.year}
                                                            </div>
                                                            <div className={classes.month}>
                                                                {article.month ? onGetMonthName(article.month, language) : ''}
                                                            </div>
                                                            <div className={classes.day}>
                                                                / {article.day}
                                                            </div>
                                                        </div>
                                                        
                                                    </Grid>
                                                </Grid>
                                                

                                            </Grid>
                                        </Grid>
                                        {/*<div className={classes.date_pattern}>
                                            <Grid container wrap='nowrap' spacing={3}>
                                                <Grid item>
                                                    <Grid container direction='column' alignItems='center' spacing={1}>
                                                        <Grid item>
                                                            <div className={classes.circle_text} style={{border:'1px solid #084EB6'}}>
                                                                <div className={classes.circle_data} >
                                                                    {month}
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction='column' alignItems='center' spacing={1}>
                                                        <Grid item>
                                                            <div className={classes.circle_text} style={{border:'1px solid transparent'}}>
                                                                <div className={classes.circle_data} >
                                                                    {day}
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                    </div>*/}
                                        
                                    </div>
                                    <div className={classes.richtext}>
                                        <ReactMarkdown children={article ? article.content : nodata} escapeHtml={false}  />
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    )}
                    
                    
                </div>
            </div>
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 
export default connect(mapStateToProps)(ArticleView) ;

const useStyles = makeStyles(theme => ({
    date_container:{
        display:'flex',
        fontSize:18,
        flexWrap:'no-wrap'
    },
    year:{
        color:'#084EB6',
        marginRight:24
    },
    month:{
        marginRight:8
    },
    root:{
        padding:'100px 0px',
        background:'#1C1C1C',  
        color:'#C4C4C4',
        fontSize:'0.8rem',
        minHeight:'100vh',
        fontFamily:theme.typography.fontFamily
        
        //color:'#F4F4F4',
    },
    container:{
        display:'flex',
        flexWrap:'no-wrap'
    },
    subcontainer:{
        position:'relative',
        //  marginLeft:80,
        marginTop:400,
        marginRight:40
    },
    left_container:{
        flex:1
    },
    right_container:{
        width:1200,
        //paddingRight:'100px',
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            width:'100%',
            margin:0,
            boxSizing:'border-box',
            padding:16
         },
    },
    pattern_container:{
        width:'70%',
        //paddingTop:'50%',
        //border:'1px solid white',
        position:'absolute',
        zIndex:2,
        top:0,
        left:0,
        transform:'translate(0%,25%)',
        zIndex:3,

    },
    decoration_pattern:{
        marginTop:'20%',
        marginLeft:'20%'
    },
    circle_container:{
        width:'80%',
        paddingTop:'80%',
        //paddingTop:'50%',
        border:'1px solid white',
        position:'absolute',
        zIndex:2,
        bottom:0,
        left:0,
        transform:'translate(-50%,40%)',
        zIndex:0,
        background: '#184EA2',
        borderRadius:'50%',
        filter:'blur(100px)'
    },
    image_container:{
        
        marginBottom:90,
        position:'relative',
        display:'flex',
        flexWrap:'no-wrap'
    },
    image_pattern:{
        width:202,
        [theme.breakpoints.down('md')]:{
            width:'50%',
        },
    },
    image:{
        width:800,
        [theme.breakpoints.down('md')]:{
            width:'100%',
        },
    },
    content_container:{
        width:800,
        [theme.breakpoints.down('md')]:{
            width:'100%',
        },
    },
    title:{
        fontSize:24,
        paddingBottom:16,
        borderBottom:'1px solid white',
        marginBottom:40,
        position:'relative'
    },
    richtext:{
        fontSize:16,
        fontWeight:300
    },
    dateformat:{
        fontSize:16
    },
    date_pattern:{
        position:'absolute',
        right:-165,
        top:-9,
    },
    circle_text:{
        position:'relative',
        width:50, height:50,
        borderRadius:'50%'
    },
    circle_data:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        fontSize:16
    },
    return_link:{
        color:'#74a7fa',
        fontFamily:theme.typography.fontFamily,
        alignItems:'center',
        display:'flex',
        fontSize:16,
        marginBottom:16,
        cursor:'pointer'
    }
    
}))