import { Fade, Grid, Hidden, Icon, makeStyles, Slide, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import system_translations from "../../texts/system_translations"


const TestimonyView = ({language}) => {

    const classes = useStyles()
    const content = system_translations[language].views.testimony

    const [section_dispatcher, setSectionDispatcher] = useState({
        constitutional:false,
        administrative:false,
    })

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const onUpdateDispatcher = (data) => {
        setSectionDispatcher({...section_dispatcher, ...data})
    }

    
    
    return ( 
        <div className={classes.root} >
            <div className={classes.container}>
                <div className={classes.left_container}>
                    <div className={classes.text}>
                        {content.left_side.text}
                    </div>
                    <div className={classes.pattern1_container}>
                        <Fade in timeout={1000} >
                            <div><img src={content.left_side.pattern}  className={classes.pattern}/></div>
                        </Fade>
                    </div>
                    <div className={classes.circle_container}>
                        
                    </div>
                    <div className={classes.pattern2_container}>
                            <Fade in timeout={1000} >
                                <div><img src={content.left_side.pattern2}  className={classes.pattern2}/></div>
                            </Fade>
                        </div>
                </div>
                <div className={classes.right_container}>
                    <Grid container spacing={4} >
                        {content.right_side.testimonys.map((item)=>{
                            return(
                                <Grid item md={6} xs={12}>
                                    <TestimonyItem key={item} icon={content.right_side.icon} text={item}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <div className={classes.logos_container}>
                        <Grid container spacing={3} alignItems='center' className={classes.center}>
                            
                            {content.logos.map((item, index) => {
                                return(
                                    <Grid item key={index.toString()}>
                                        <div className={classes.logo_cont} ><img alt='' src={item} className={classes.logo}/></div>
                                    </Grid>
                                )
                            })}
                          
                        </Grid>
                    </div>
                    
                </div>
            </div>
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 
export default connect(mapStateToProps)(TestimonyView) ;

const useStyles = makeStyles(theme => ({
    root:{
        padding:'100px 0px',
        background:'#1C1C1C',  
        color:'#C4C4C4',
        fontSize:'0.8rem',
        minHeight:'100vh',
        //background:'red',
        //width:'100%',
        overflowY:'hidden',
        
        //color:'#F4F4F4',
        [theme.breakpoints.down('sm')]:{
            overflowY:'auto'
            //background:'red'
         },
    },
    container:{
        display:'flex',
        flexWrap:'no-wrap',
        height:'100%',
        [theme.breakpoints.down('md')]:{
            flexWrap:'wrap',
            display:'inline'
            //background:'red'
         },
    },
    left_container:{
        flex:1,
        position:'relative',
        [theme.breakpoints.down('sm')]:{
            //flex:'none',
            //width:'100%',
            //background:'red'
            flex:'auto'
         },
        //background:'red',
        //overflowY:'hidden',
        //overflowX:'auto'
        //height:'100%'
    },
    right_container:{
        width:1200,
        paddingRight:180,
        boxSizing:'border-box',
        paddingBottom:100,
        [theme.breakpoints.down('md')]:{
            width:'100%',
            margin:0,
            padding:32,
            boxSizing:'border-box',
            //background:'red',
         },
        
    },
    text:{
        marginLeft:80,
        width:285,
        fontFamily:'Alda OT CEV',
        fontSize:18,
        color:'white',
        letterSpacing:'0.005em',
        lineHeigth:25

    },
    pattern1_container:{
        position:'absolute',
        width:'100%',
        top:'20%',
        right:0,
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    },
    pattern:{
        width:'55%'
    },
    pattern2_container:{
        position:'absolute',
        width:'100%',
        bottom:'-12%',
        left:'56%',
        zIndex:2,
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    },
    pattern2:{
        width:'30%'
    },
    circle_container:{
        width:'80%',
        paddingTop:'80%',
        //paddingTop:'50%',
        border:'1px solid white',
        position:'absolute',
        top:'20%',
        left:'60%',
        transform:'translate(-40%,130%)',
        zIndex:1,
        background: '#184EA2',
        borderRadius:'50%',
        filter:'blur(100px)',
        [theme.breakpoints.down('lg')]:{
            top:'55%',
            left:'60%',
        },
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    },
    logos_container:{
        marginTop:100,
        //display:'flex'
        
    },
    center:{
        [theme.breakpoints.down('sm')]:{
            justifyContent:'center',
            //display:'flex',
        },
    },
    logo_cont:{
        width:120,
        padding:'0px 32px',
        [theme.breakpoints.down('md')]:{
            padding:'0px 16px',
        },
        [theme.breakpoints.down('sm')]:{
            padding:0
        },
        [theme.breakpoints.down('xs')]:{
            width:100
        }
    },
    logo:{
        width:'100%',
        height:'100%',
        objectFit:'contain'
    }
    
    
}))


const TestimonyItem = ({icon, text}) => {

    const classes = useItemStyles()

    return(
        <div className={classes.root}>
            <Grid container spacing={1} wrap='nowrap'>
                <Grid item>
                    <div className={classes.icon_container}><img src={icon} alt='' /></div>
                </Grid>
                <Grid item>
                    <div className={classes.body}>{text}</div>
                </Grid>
            </Grid>
        </div>
    )
}

const useItemStyles = makeStyles(theme => ({
    root:{
        //paddingRight:40
        
    },
    icon_container:{
        marginRight:24
    },
    body:{
        fontFamily:'Alda OT CEV',
        color:'#C4C4C4',
        fontStyle:'italic',
        fontSize:18
    }
    
    
    
}))