import blog_asset_1 from '../../assets/blogs/blog-1.png'
import blog_asset_2 from '../../assets/blogs/blog-2.png'
import blog_asset_3 from '../../assets/blogs/blog-3.png'

import office1 from '../../assets/office1.png'
import office2 from '../../assets/office2.png'
import office3 from '../../assets/office3.png'
import columna from '../../assets/roof.jpg'
import pattern from '../../assets/office_pattern.svg'


export const translations = {
    paragraph1:'Despacho especializado en atender litigios empresariales complejos, reconocido por las publicaciones internacionales de mayor prestigio en la materia, como uno de los despachos líderes en la resolución de controversias en México.  Nuestros resultados e historia nos avalan: somos un despacho único que consistentemente excede las expectativas de nuestros clientes.',
    paragraph2:'Participamos activa e intensamente, con el mismo nivel de excelencia, en litigios de naturaleza administrativa, civil, constitucional, mercantil, y arbitrajes nacionales e internacionales.  A siete años de nuestra fundación, nos hemos consolidado como el despacho mexicano al cual acudir cuando no hay margen de error; cuando la complejidad y sofisticación de la disputa exigen estrategia y ejecución impecables; y cuando no hay un camino aparente y es necesario abrir brecha.',
    paragraph3:'Único en su clase, Martens se integra por un sólido y diverso grupo de profesionales rigurosamente entrenados, y se distingue por un alto y consistente índice de victorias, así como por su capacidad para desarrollar e implementar estrategias y argumentos que culminan como precedentes judiciales relevantes para el orden jurídico mexicano.',
    paragraph4:'Nuestro equipo de profesionales tiene un profundo conocimiento del orden jurídico mexicano y su interpretación, así como extensa experiencia judicial ante tribunales federales y locales. No improvisamos; una vez que decidimos atender un asunto, éste se analiza meticulosamente; se desarrollan y contrastan distintas visiones y estrategias; anticipamos y prevemos distintos escenarios; y cada decisión procesal es cuidadosamente ponderada.',
    main_image:office1,
    medium_image:columna,
    small_image1:office2,
    small_image2:office3,
    pattern:pattern
    
}

export default translations