import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames'
import { grey } from '@material-ui/core/colors';

const MemberList = ({employees, selected, onChange}) => {

    const classes = useStyles()

    //console.log(employees)

    return ( 
        <div className={classes.root}>
            {employees.map(item => {
                return(
                    <div className={classes.container} key={item.id.toString()} >
                        {/*<div className={classes.pattern}>
                            <div className={classes.vertical_line}/>
                            {selected.id === item.id ? <div className={classes.box}/> : null}
                </div>*/}
                    <div style={{display:'inline-flex '}}>
                        <div className={cx({
                            [classes.name]:true,
                            [classes.name_selected]: selected.id === item.id
                        })} onClick={() => onChange(item)}>
                            {item.name}
                        </div>
                    </div>
                        
                    </div>
                )
            })}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        fontFamily:'alda',
        fontSize:18,
        fontWeight:300,
        marginRight:40
        
    },
    container:{
        position:'relative',
        display:'flex',
        flexWrap:'nowrap',
        alignItems:'strech'
    },
    name:{
        flex:1, 
        
        padding:'6px 20px',
        margin:'4px 0px',
        boxSizing:'border-box',
        cursor:'pointer',
        transition:'all 0.1s linear',
        '&:hover':{
            background:theme.palette.secondary.main,
            color:'white',
            transition:'all 0.4s linear'
        },
        color:grey[600]
    },
    name_selected:{
        background:theme.palette.secondary.main,
        transition:'all 0.4s linear',
        //opacity:'none',
        color:'white',
        '&:hover':{
            transition:'all 0.4s linear',
            background:theme.palette.secondary.main
        }
    },
    pattern:{
        width:60, minHeight:'100%', maxHeight:'100%',
        position:'relative', boxSizing:'border-box'
        //border:'1px solid white'
    },
    vertical_line:{
        width:2,
        minHeight:'100%', maxHeight:'100%',
        background:theme.palette.secondary.main,
        position:'absolute',
        top:0, left:'50%', transform:'translateX(-50%)'
        //margin:'auto',
    },
    box:{
        position:'absolute', 
        top:'50%', left:'50%', transform:'translate(-50%, -50%)',
        width:19,
        height:19,
        background:theme.palette.secondary.main,
    }
}))
 
export default MemberList;