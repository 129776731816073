import shipping_icon from '../../assets/icons/shipping.svg'
import center_call_icon from '../../assets/icons/center-call.svg'
import offers_icon from '../../assets/icons/offers.svg'

import baby_icon from '../../assets/icons/baby.png'
import drugs_icon from '../../assets/icons/pill.png'
import aids_icon from '../../assets/icons/first-aids.png'
import nutrition_icon from '../../assets/icons/nutrition.png'
import skin_icon from '../../assets/icons/skin.png'
import mainImage from '../../assets/values/main-image.jpg'
import secondaryImage from '../../assets/values/secondary-image.jpg'
import pattern from '../../assets/values/pattern.svg'

export const translations = {
    main_image:mainImage,
    second_image:secondaryImage,
    pattern:pattern,
    radius_pattern:null,
    confidence:{
        title:'Confidence',
        text:'Our client\'s trust is invaluable; it is built in decades and lost in an instant.'
    },
    solution:{
        title:'Solution',
        text:'Our client\'s problems are our problems, and every problem has a solution.'
    },
    dedication:{
        title:'Dedication',
        text:'Regardless the diversity of factors involved in litigation, we must assume that the success of a dispute depends exclusively on our own ability, effort and dedication.'
    },
    honesty:{
        title:'Honesty',
        text:'Honesty is everything. A litigation lawyer without credibility must choose another profession.'
    },
    ethics:{
        title:'Ethics',
        text:'Our actions are subject to the highest ethical and professional standards. Nothing justifies sacrificing our integrity.'
    },
    excellence:{
        title:'Excellence',
        text:'Excellence is what makes us unique; therefore, each and every dispute that we handle is top priority.'
    },
    undertaking:{
        title:'Commitment',
        text:'Our profession requires responsiveness and total professional commitment. We owe it to our clients, and we are here to serve them.'
    },
    sensitivity:{
        title:'Sensitivity',
        text:'Helping others is our privilege. We must be sensitive to the social reality and assist those whose access to justice may be compromised.'
    },
    
}

export default translations