import comillas from '../../assets/testimony/icon.svg'
import pattern from '../../assets/testimony/pattern.svg'
import pattern2 from '../../assets/testimony/pattern2.svg'

import firm from '../../assets/testimony/logos/firm.jpeg'
import generation from '../../assets/testimony/logos/generation.jpeg'
import lawyer from '../../assets/testimony/logos/lawyer.jpeg'
import logo from '../../assets/testimony/logos/Logo.png'
import logo_chambers from '../../assets/testimony/logos/LogoChambers.jpeg'

export const translations = {
    left_side:{
        pattern:pattern,
        pattern2:pattern2,
        text:'Las publicaciones internacionales de mayor prestigio en la materia, han recabado de manera independiente algunas opiniones de nuestros clientes:',
    },
    right_side:{
        icon:comillas,
        testimonys:[
            '“Son especialistas en litigio, entienden las necesidades de los clientes a la perfección y atienden los problemas de manera innovadora.”',
            '“Gran servicio, atención rápida y excelente preparación. Son un equipo muy proactivo y conocen de la materia. Entregan reportes de manera ejecutiva y puntual del progreso de los procedimientos. Son muy proactivos y proporcionan opciones con sustento legal. Intentan conocer a detalle la operación de la compañía y su sector, lo cual es importante.”',
            '“Son capaces de proporcionar sensibilidad real en las diferentes estrategias y aproximaciones a un litigio exitoso, y complementar sus planteamientos con análisis que ayudan a los tribunales a entender el litigio y su impacto.”',
            '“Siempre están dispuestos a explicar las cosas de manera simple de manera que refuerza la confianza y la toma de decisiones de las compañias.”',
            '“El equipo tiene gran conocimiento de las leyes de México y tiene una perspectiva estratégica para atender litigios. Antes de empezar cualquier tipo de procedimiento judicial, llevan a cabo un análisis meticuloso del asunto anticipando cualquier contingencia que pueda surgir en su desarrollo. Siguen puntualmente los juicios encomendados, promoviendo los procesos en todas sus etapas. Recomendados por: 1. Disponibilidad de tiempo. 2.- Facilidad de comunicación. 3.- Adaptación a las necesidades del cliente.”',
            '“Personalizados, directos, puntuales y servicio transparente. Precios competitivos y gran imaginación para proponer estrategias legales apropiadas. Proporcionan gran confianza. Comunican el desarrollo de los asuntos de manera puntual, así como los posibles resultados que de la disputa podrían surgir. Son muy dedicados y toman los casos de sus clientes como si fueran de ellos.”',
            '“Es un despacho que entiende las necesidades del cliente en materia de litigio y proporciona seguridad para defender los intereses de la compañía ante los tribunales. Es un despacho excepcional en términos de tiempos de respuesta y claridad para presentar la estrategia legal para seguir en tribunales.”'
        ]
        
    },
    logos:[
        firm, logo, logo_chambers, generation, lawyer
    ]
  
    
}

export default translations