import { Fade, Grid, Hidden, Icon, makeStyles, Slide, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import ImageContainer from '../../components/Structure/DisplayData/ImageContainer';
import system_translations from "../../texts/system_translations"
import cx from 'classnames';
import LitigationSection from './components/LitigationSection';
import ConstitutionalSection from './components/ConstitutionalSection';
import AdministrativeSection from './components/AdministrativeSection';
import InViewMonitor from 'react-inview-monitor';
import AdminConstSection from './components/AdminConstSection';
import TopbarMenu from './components/TopbarMenu';
import TopbarMenuMobile from './components/TopbarMenuMobile';

const PracticesView = ({language}) => {

    const classes = useStyles()
    const content = system_translations[language].views.practices

    const [section_dispatcher, setSectionDispatcher] = useState({
        constitutional:false,
        administrative:false,
    })

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const onUpdateDispatcher = (data) => {
        setSectionDispatcher({...section_dispatcher, ...data})
    }

    const [view, setView] = useState(1);

    const leftContent = (
        <div className={classes.left_container}>
            <div className={classes.subcontainer}>
                <Slide in timeout={1500} direction='right' >
                    <div>
                        <img src={content.main_image} width='75%' style={{zIndex:3, position:'relative'}} />
                    </div>
                </Slide>
                <div className={classes.pattern_container}>
                    <Fade in timeout={3000} >
                        <div><img src={content.main_pattern} width='100%' className={classes.pattern_image}/></div>
                    </Fade>
                    
                </div>
                <div className={classes.decoration_pattern}>
                    <Slide in timeout={1500} direction='right' >
                        <div>
                            <img src={content.decoration} width='20%' />
                        </div>
                    </Slide>
                </div>

                <div className={classes.circle_container}/>

            </div>
        </div>
    )

    let contentView

    if(view === 1){
        contentView = <Fade in timeout={2000}>
            <div>
                <AdminConstSection content={content.admin_constitutional}/>
            </div>
        </Fade>
    }

    if(view === 2){
        contentView = <Fade in timeout={2000}>
            <div>
                <AdminConstSection content={content.civil_mercantil_ar}/>
            </div>
        </Fade>
    }
    
    return ( 
        <div className={classes.root} >
            <div className={classes.container}>
                <Hidden smDown>
                    {leftContent}
                </Hidden>
                <div className={classes.right_container}>
                    <Hidden smDown>
                        <div className={classes.right_subcontainer}>
                            <Grid container spacing={5}>
                                <Grid item md={6} xs={12}>
                                    <Fade in timeout={2000}>
                                        <div>
                                            <AdminConstSection content={content.admin_constitutional}/>
                                        </div>
                                    </Fade>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Fade in timeout={2000}>
                                        <div>
                                            <AdminConstSection content={content.civil_mercantil_ar}/>
                                        </div>
                                    </Fade>
                                </Grid>
                            </Grid>
                        </div>  
                    </Hidden>      
                    <Hidden mdUp>
                        <TopbarMenuMobile selected={view} options={content.menu} onChange={(id)=>setView(id)} />
                        <div className={classes.right_subcontainer}>
                            {contentView}
                        </div> 
                    </Hidden>          
                </div>
            </div>
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 
export default connect(mapStateToProps)(PracticesView) ;

const useStyles = makeStyles(theme => ({
    root:{
        padding:'100px 0px',
        background:'#1C1C1C',  
        color:'#C4C4C4',
        fontSize:'0.8rem',
        minHeight:'100vh',
        [theme.breakpoints.down('md')]:{
            padding:'20px 0px'
         },
        
        //color:'#F4F4F4',
    },
    container:{
        display:'flex',
        flexWrap:'no-wrap'
    },
    subcontainer:{
        position:'relative',
        //marginLeft:80,
        marginRight:40
    },
    left_container:{
        flex:1
    },
    right_container:{
        width:1200,
        //paddingRight:'100px',
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            width:'100%',
            margin:0,
            padding:32,
            boxSizing:'border-box'
         },
    },
    right_subcontainer:{
        width:800,
        [theme.breakpoints.down('md')]:{
            width:'100%',
         },
    },
    pattern_container:{
        width:'50%',
        //paddingTop:'50%',
        //border:'1px solid white',
        position:'absolute',
        zIndex:2,
        bottom:'10%',
        left:0,
        //transform:'translate(10%,20%)',
        zIndex:1,

    },
    decoration_pattern:{
        marginTop:'20%',
        marginLeft:'60%'
    },
    circle_container:{
        width:'80%',
        paddingTop:'80%',
        //paddingTop:'50%',
        border:'1px solid white',
        position:'absolute',
        top:'-15%',
        left:'-35%',
        transform:'translate(-40%,130%)',
        zIndex:1,
        background: '#184EA2',
        borderRadius:'50%',
        filter:'blur(100px)'
    },
    
}))