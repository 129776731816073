import React, { useState, useEffect } from 'react';
import mainbanner from '../../../assets/banners/main.jpg'
import mainbannergrey from '../../../assets/banners/main-grey.jpg'
import { alpha, Fade, makeStyles } from '@material-ui/core';
import cx from 'classnames'

const MainBanner = () => {

    const classes = useStyles()
    const [iscolor, setIsColor] = useState(false)
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        setTimeout(onTransformToColor, 3100)
    }, [])
    
    const onTransformToColor = () => {
        setIsColor(true)
        setTimeout(onShutDown, 2000)
    }

    const onShutDown =() => {
        setVisible(false)
    }



    return ( 
        <div>
            <Fade in={visible} timeout={3000}>
                <div className={cx({[classes.root]:true, [classes.root_disabled]:!visible})}>
                        <img src={mainbanner} className={cx({
                            [classes.banner]:true,
                            [classes.banner_color]:iscolor,
                        })} />
                    
                </div>
            </Fade>|
            {visible ? <div className={cx({[classes.base]:true})}></div> : null}
        </div>
        
    );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:alpha('#000',1),
        width:'100%',
        height:'100vh',
        position:'fixed',
        zIndex:2500,
        
    },
    base:{
        background:alpha('#000',1),
        width:'100%',
        height:'100vh',
        position:'fixed',
        zIndex:2000,
        
    },
    root_disabled:{
        //zIndex:-1,
        //animation: 'linear 10s all'
    },
    banner:{
        filter: 'grayscale(1)',
        animation: 'linear 1s all',
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    banner_color:{
        filter: 'grayscale(0.01)',
        animation: 'linear 5s all'
    },
    
}))
 
export default MainBanner;