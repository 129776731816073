import { Fade, Grid, Icon, makeStyles, Slide, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import home2 from '../../assets/home_view/home2.png'
import home3 from '../../assets/home_view/home3.png'
import ImageContainer from '../../components/Structure/DisplayData/ImageContainer';
import system_translations from "../../texts/system_translations"
import cx from 'classnames';
import InViewMonitor from 'react-inview-monitor';


const OfficeView = ({language}) => {

    const classes = useStyles()
    const content = system_translations[language].views.office

    const [selected, setSelected] = useState(0)
    const [section_dispatcher, setSectionDispatcher] = useState({
        imageA:false,
        imageB:false,
        imageC:false,
        textA:false
    })

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const onUpdateDispatcher = (data) => {
        setSectionDispatcher({...section_dispatcher, ...data})
    }

    const onGoingNext = () => {
        if(selected === content.carrousel.length -1 ){
            setSelected(0)
        }else{
            setSelected(selected + 1 )
        }
    }

    return ( 
        <div className={classes.root} >
            <div className={classes.container}>
                <div className={classes.left_container}/>
                <div className={classes.right_container}>
                    <div className={classes.subroot}>
                        <Grid container spacing={5}>
                            <Grid item md={6} xs={12}>
                                <Fade in timeout={1500}>
                                    <div>
                                        <Typography>{content.paragraph1}</Typography>
                                    </div>
                                </Fade>
                                <div className={classes.main_image_wrapper}>
                                    <div className={classes.main_image_container}>
                                        <div className={classes.main_image}>
                                            <Fade in timeout={1500} direction='right' >
                                            
                                                <div className={classes.image_wrapper}>
                                                    <img alt='' className={classes.image} src={content.main_image}/>
                                                </div>
                                            </Fade >
                                        </div>
                                    </div>
                                </div>
                                <InViewMonitor onInView={()=> onUpdateDispatcher({imageA:true})} >
                                    <Fade in={section_dispatcher.imageA} timeout={1500} direction='right' >
                                        <div className={classes.paragraph_wrapper} style={{position:'relative'}}>
                                            

                                                <div className={classes.small_image}>
                                                    
                                                        <div className={classes.image_wrapper} style={{zIndex:5}}>
                                                            <img alt='' className={classes.image} src={content.small_image1}/>
                                                        </div>
                                                    
                                                </div>
                                            
                                            {/* <div className={classes.small_deco_container}>
                                                <div className={classes.small_image}>
                                                    <div className={classes.image_wrapper}>
                                                        <div className={classes.small_deco}></div>
                                                    </div>
                                                </div>
                                            </div> */}                    
                                        </div>
                                    </Fade >
                                </InViewMonitor>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Fade in timeout={1500}>
                                    <div>
                                        <Typography>{content.paragraph2}</Typography>
                                    </div>
                                </Fade>
                                <Fade in timeout={1500}>
                                    <div className={classes.paragraph_wrapper}>
                                        <Typography>{content.paragraph3}</Typography>
                                    </div>
                                </Fade>
                                {/* <div className={classes.paragraph_wrapper}>
                                    <div>
                                        <InViewMonitor onInView={()=> onUpdateDispatcher({imageB:true})} >
                                            <div className={classes.medium_image}>
                                                <Fade in={section_dispatcher.imageB} timeout={1500} direction='left' >
                                                    <div className={classes.image_wrapper} >
                                                        <img alt='' className={classes.image} src={content.medium_image}/>
                                                    </div>
                                                </Fade >
                                            </div>
                                        </InViewMonitor>
                                    </div>
                                </div> */}
                                {/* <InViewMonitor onInView={()=> onUpdateDispatcher({textA:true})} >
                                    <Fade in={section_dispatcher.textA} timeout={1500} >
                                        <div className={classes.paragraph_wrapper_blue} >
                                            <Typography style={{color:'white'}}>{content.paragraph4}</Typography>
                                        </div>
                                    </Fade>
                                </InViewMonitor> */}
                                <InViewMonitor onInView={()=> onUpdateDispatcher({imageC:true})} >
                                    <div className={classes.small_image_wrapper}>
                                        <div className={classes.small_image}>
                                            <Fade in={section_dispatcher.imageC} timeout={1500} >

                                                <div className={classes.image_wrapper}>
                                                    <img alt='' className={classes.image} src={content.small_image2}/>
                                                </div>
                                            </Fade>
                                        </div>
                                    </div>
                                </InViewMonitor>
                                <InViewMonitor onInView={()=> onUpdateDispatcher({textA:true})} >
                                    <Fade in={section_dispatcher.textA} timeout={1500} >
                                        <div className={classes.paragraph_wrapper_blue} >
                                            <Typography style={{color:'white'}}>{content.paragraph4}</Typography>
                                        </div>
                                    </Fade>
                                </InViewMonitor>
                            </Grid>
                            <Grid xs={12}>
                                <div style={{marginLeft:20, marginTop:12}}>
                                <Fade in timeout={2000} >
                                    <div >
                                        <img alt='' className={classes.image}  src={content.pattern}/>
                                    </div>
                                    </Fade>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <div className={classes.bottom_rectangle}></div>
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 
export default connect(mapStateToProps)(OfficeView) ;

const useStyles = makeStyles(theme => ({
    root:{
        padding:'100px 0px 0px',
        background:'#1C1C1C',  
        //color:'#F4F4F4',
    },
    container:{
        display:'flex',
        flexWrap:'no-wrap',
    },
    left_container:{
        flex:1
    },
    right_container:{
        width:1200,
        //paddingRight:'100px',
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            width:'100%',
            margin:0,
            boxSizing:'border-box'
         },
    },
    subroot:{
        width:800,
        color:'white',
        
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            padding:'0px 40px',
            //background:'red',
            boxSizing:'border-box'
         },
    },
    main_image_container:{
        position:'absolute',
        top:0,
        right:0,
        //paddingRight:800,
        //left:0,
        //width:945,
        //background:'red',
        //height:500,
        width:690,
        height:500,
        [theme.breakpoints.down('sm')]:{
            width:'100%'
         },
    },
    main_image:{
        width:'100%',
        //background:'red',
        position:'relative',
        //paddingTop:'75%',
        boxSizing:'border-box',
        //height:500
        height:500
        //overflowX:'hidden'
    },
    main_image_wrapper:{
        margin:'40px 0px 20px',
        position:'relative',
        height:500
        //height:500
    },
    medium_image:{
        width:'100%',
        //background:'red',
        position:'relative',
        paddingTop:'156%',
        boxSizing:'border-box',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            paddingTop:'156%',
         },
    },
    small_image:{
        width:'100%',
        //background:'red',
        position:'relative',
        paddingTop:'83%',
        boxSizing:'border-box',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            paddingTop:'83%'
         },
    },
    image_wrapper:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        //background:'green'
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover',
    },
    paragraph_wrapper:{
        padding:'20px 0px',
    },
    paragraph_wrapper_blue:{
        padding:'20px 0px',
        //width:'70%',
        [theme.breakpoints.down('sm')]:{
            width:'100%'
         },
    },
    small_image_wrapper:{
        padding:'20px 0px'
    },
    
    small_deco:{
        width:'120%',
        height:'120%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 3%, #084EB6 4%)',
        [theme.breakpoints.down('sm')]:{
            display:'none'
         },
    },
    small_deco_container:{
        position:'absolute',
        width:'100%',
        height:'100%',
        top:'25%',
        right:'40%'
    },
    bottom_rectangle:{
        width:'100%',
        height:50,
        background:'#303030',
        marginTop:200
    }
    
}))