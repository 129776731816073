import moment from "moment"
import { useState, useEffect } from "react"
import { LANG_LOCALE, public_server } from "../../../config"

const useArticleView = ({ language, content, match}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [article, setArticle] = useState({})
    

    useEffect(() => {
        window.scrollTo(0,0)
        actions.onInitModule()
    }, [])
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                const params = {
                    //_sort:'id:desc',
                    locale:LANG_LOCALE[language]
                }
                const article_id = match.params.id
                console.log(match.params.id)
                const request = await public_server.get(`/articles/${article_id}`, params)
                console.log(request.data)

                let _data = {...request.data}

                const current_date = moment(_data.published_date)
                const current_month = current_date.month()+1
                const current_day = current_date.date()

                _data.year = current_date.year()
                _data.month = current_month < 10 ? `0${current_month}` : current_month
                _data.day = current_day < 10 ? `0${current_day}` : current_day
                setArticle(_data)
                
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
    }

    const system = {loading, sending, error}
    const view_data = {article}

    return {system, view_data, actions}
}

export default useArticleView
