import team from '../../assets/team/general-team2.jpg'
import team_pattern from '../../assets/team/team-pattern2.svg'
import team1 from '../../assets/team/team1.png'
import team_pattern_black from '../../assets/team/team-pattern-black.svg'


export const translations = {
    left_side:{
        text:'Tenemos un riguroso procedimiento de selección de pasantes y abogados, quienes son sometidos a un intenso y permanente entrenamiento técnico y práctico para cumplir nuestro compromiso: brindarles a nuestros clientes asesoría jurídica de excelencia.',
        image:team
    },
    image:team,
    pattern:team_pattern,
    small_image:team1,
    pattern_black:team_pattern_black,
    textContent:'Tenemos un riguroso procedimiento de selección de pasantes y abogados, quienes son sometidos a un intenso y permanente entrenamiento técnico y práctico para cumplir nuestro compromiso: brindarles a nuestros clientes asesoría jurídica de excelencia.',
    toolbar:[
        {id:1, key:'overview', active: true, label:'Resumen'},
        {id:2, key:'education_experience', active: true, label:'Educación & Experiencia'},
        {id:3, key:'academy', active: true, label:'Academia'},
        {id:4, key:'colleges', active: true, label:'Colegios'},
        {id:5, key:'testimonials', active: true, label:'Testimonios'},
    ],
    noinfo:'No existe información registrada aún',
    right_side:{
        toolbar:[
            {id:1, label:'Resumen'},
            {id:2, label:'Educación & Experiencia'},
            {id:3, label:'Academia'},
            {id:4, label:'Colegios'},
        ],
        member_bar:['Resumen', 'Educación y experiencia', 'Academia', 'Colegios'],
        team:[
            {
                name:'Federico Martens', 
                summary:'Con una trayectoria de quince años litigando ante tribunales federales y locales, y un impresionante índice de victorias judiciales, Federico es reconocido como uno de los abogados litigantes líderes en México por las publicaciones internacionales de mayor prestigio: Chambers & Partners, Legal500 y Leaders League, las cuales lo han descrito como “excepcional”, “altamente experto”, “creativo” y “extremadamente responsivo”. Disciplinado y meticuloso, Federico analiza cada detalle de los litigios que atiende con una visión creativa y multidisciplinaria única en su clase en México, lo que le permite identificar soluciones creativas y eficaces a los problemas más críticos que sus clientes enfrentan.',
                education:[
                    'Universidad Iberoamericana, Licenciatura en Derecho.',
                    'Galicia Abogados.',
                    'Mijares, Angoitia, Cortés y Fuentes.',
                ],
                academy:[
                    'Profesor titular de Derecho Procesal en la Universidad Iberoamericana desde 2014.',
                    'Profesor y examinador invitado en la Universidad Panamericana, Instituto Tecnológico Autónomo de México y Universidad Anáhuac, en las materias de Amparo, Obligaciones, Controversias Corporativas Complejas, Daños en Materia de Competencia Económica, entre otras.',
                    'Autor, entre otros, de los artículos especializados Hacia una Suspensión Efectiva en el Juicio de Amparo; Control Constitucional por Tribunales Arbitrales; Barreras a la Competencia y su Remediación; Rescisión de los Contratos de Exploración y Extracción de Hidrocarburos.',
                    'Expositor y panelista frecuente en mesas de análisis y foros especializados.'
                ],
                colleges:[
                    'Barra Mexicana de Abogados, A.C',
                    'Asociación Nacional de Abogados de Empresa, A.C.',
                    'International Bar Association'
                ]
            },
            {
                name:'Mirem Arriola', 
                summary:'Mirem es la Directora General de Martens Abogados; dirige y supervisa el desempeño del despacho bajo criterios estrictos de buen gobierno corporativo.  Lidera el crecimiento ordenado y sostenido de Martens hacia convertirlo en el despacho líder en resolución de controversias en México. Con su experiencia de más de 15 años asesorando empresas y atendiendo transacciones complejas, frecuentemente apoya y supervisa el desarrollo de estrategias y argumentos en litigios corporativos y financieros complejos en los que el despacho representa tanto a actores como a demandados.',
                education:[
                    'Northwestern University, Maestría en Derecho corporativo y financiero.',
                    'Universidad Iberoamericana, Licenciatura en Derecho.',
                    'Mijares, Angoitia, Cortés y Fuentes.',
                    'Cleary, Gottlieb, Steen & Hamilton, Nueva York.'
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Luis G. Gonzalez Meyer', 
                summary:'Abogado especializado en litigio con más de 10 años de experiencia en controversias mercantiles, civiles, administrativas y constitucionales de suma importancia y trascendencia.  Diariamente interviene en procedimientos complejos que involucran los intereses de mayor relevancia para la subsistencia de sus clientes. Luis ha atendido exitosamente un sinnúmero de procesos administrativos, civiles, constitucionales y mercantiles, desde el diseño de la estrategia contenciosa, hasta la última etapa de ejecución de sentencias.  Asimismo, Luis regularmente asesora a empresas en la identificación, prevención y en su caso mitigación de contingencias altamente sensibles. Ha impartido cátedras a nivel licenciatura y diplomado en distintas materias procesales tales como Derecho Procesal Civil, Juicios Orales Mercantiles y Teoría General del Proceso, tanto en la Universidad Iberoamericana como en el Instituto de la Judicatura Federal.',
                education:[
                    'Universidad Iberoamericana, Licenciatura en Derecho.',
                    'Galicia Abogados.',
                    'Mijares, Angoitia, Cortés y Fuentes.'
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Erick Rodríguez Meneses', 
                summary:'Abogado especializado en litigio con más de 5 años de experiencia en controversias comerciales, civiles, administrativas y constitucionales sensibles y complejas.  Erick ha diseñado, participado y conducido de forma activa y meticulosa las estrategias y procedimientos de amparo seguidos en sectores altamente regulados.',
                education:[
                    'Universidad Anáhuac, México, Licenciatura en Derecho: Premio CENEVAL al Desempeño de Excelencia.',
                    'Emory University School of Law, Trial Techniques Program.',
                    'Cámara Internacional de Comercio “ICC México” y Comisión Federal de Competencia Económica" Programa/Diplomado en Competencia Económica.',
                    'Carbonell, Programa/Diplomado en Derecho Administrativo.',
                    'Yarto y Narro Abogados.'
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Rodrigo Iraola Leonel de Cervantes', 
                summary:'Rodrigo es abogado especializado en la práctica del litigio civil y mercantil. Durante más de 6 años, se ha especializado y enfocado su experiencia en la representación de clientes en litigios civiles, mercantiles y constitucionales. Especialmente en litigios complejos de naturaleza comercial y corporativa. Participa en el diseño, planificación y coordinación de estrategias litigiosas en las materias aludidas. Durante ese tiempo, ha asesorado jurídicamente a compañías de todos tamaños en diversos asuntos de litigio en toda México, representando aseguradoras, bancos, arrendadoras financieras, uniones de crédito, compañías inmobiliarias, constructoras, así como a funcionarios y directores comerciales y corporativos.',
                education:[
                    'Escuela Libre de Derecho: cursando la especialidad "Curso de Actualización de Derecho Civil.',
                    'Facultad de Derecho de la Barra Nacional de Abogados, S.C. (Excelencia Académica).',
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Karen Castro Vargas', 
                summary:null,
                education:[
                    'Universidad Panamericana, Especialidad en Amparo.',
                    'Universidad Panamericana, Licenciatura en Derecho.',
                    'Becaria de la Fundación Fausto Rico durante la Licenciatura en Derecho.',
                    'Reconocimiento por la Universidad Panamericana y la Fundación Fausto Rico como el mejor promedio de su generación.',
                    'Hogan Lovells.',
                    'Anaya Abogados.'
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Omar Saab Martínez', 
                summary:null,
                education:[
                    'Universidad La Salle, Licenciatura en Derecho.',
                    'Premio CUE a los alumnos más destacados de cada Facultad.',
                    'Cámara Internacional de Comercio, Diplomado en Arbitraje Comercial.',
                    'Universidad Panamericana, cursando la Especialidad en Derecho Procesal Civil y Mercantil.',
                    'Guati Rojo Abogados, S.C.',
                    'Notaría Pública 116 de la Ciudad de México.'
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Mauricio Asali De Alba', 
                summary:null,
                education:[
                    'Escuela Libre de Derecho, Licenciatura en Derecho.',
                    'Aguilar y Loera.',
                    'García Ángeles Abogados.'
                ],
                academy:[],
                colleges:[]
            },        
            {
                name:'Mariana López Ramírez', 
                summary:null,
                education:[
                    'Universidad Panamericana, Licenciatura en Derecho.',
                    'Primer Lugar en el concurso de Litigio Constitucional y Derechos Humanos de la Suprema Corte de la Justicia de la Nación.',
                    'Muller Abogados.'
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Daniel Cherem Zaga', 
                summary:null,
                education:[
                    'Universidad Iberoamericana, cursando la Licenciatura en Derecho.',
                    'Riquelme, Sirazi, Gómez Mont Abogados.'
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Eugenio Arañó Torres', 
                summary:null,
                education:[
                    'Universidad Iberoamericana, cursando la Licenciatura en Derecho.',
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Omar Sánchez Soto', 
                summary:null,
                education:[
                    'Universidad Nacional Autónoma de México, cursando la Licenciatura en Derecho.',
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Fernando Mendoza Quezada', 
                summary:null,
                education:[
                    'Instituto Tecnológico de México (ITAM), cursando la Licenciatura en Derecho.',
                    'Mexicanos Primero A.C.',
                    'Pérez Góngora & Asociados'
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Regina Jordán Álvarez', 
                summary:null,
                education:[
                    'Universidad Iberoamericana, cursando la Licenciatura en Derecho.',
                    'Notaría Pública 240 de la Ciudad de México.'
                ],
                academy:[],
                colleges:[]
            },
            {
                name:'Frida Salgado Román', 
                summary:null,
                education:[
                    'Universidad La Salle, cursando la Licenciatura en Derecho.'
                ],
                academy:[],
                colleges:[]
            },
        ]
    },
    
}

export default translations