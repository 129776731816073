import carrusel from '../../assets/home_view/carrusel.png'
import bannerA from '../../assets/banners/bannerA.jpg'
import bannerB from '../../assets/banners/bannerB.jpg'
import bannerC from '../../assets/banners/bannerC.jpg'


import home2 from '../../assets/home_view/home2.png'
import home3 from '../../assets/home_view/home3v2.jpg'


export const translations = {
    left_side:{
        text:'We are a unique Mexican law firm solely focused on handling',
        highlight_text:'complex disputes.'
    },
    right_side:{
        title:'Litigation at its finest.',
        paragraph1:'Each case that this firm decides to take on, is handled to the best of the abilites of a first-class team. We are passionate about what we do, and our mission is clear: provide our clients with the best legal service focused on litigation in Mexico.',
        paragraph2:'We provide creative and innovative solutions to the most sensitive and complex problems our clients face, providing exceptional results in disputes and transactions.',
        
    },
    image1:home2,
    image2:home3,
    main_image:bannerA,
    carrousel:[
        {id:1, img:bannerA},
        {id:2, img:bannerB},
        {id:3, img:bannerC},
    ]
    
}

export default translations