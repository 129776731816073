import { Grid, makeStyles, Typography, Fade, Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import ImageContainer from '../../components/Structure/DisplayData/ImageContainer';
import system_translations from "../../texts/system_translations"
import InViewMonitor from 'react-inview-monitor';
import { LANG_LOCALE, public_server } from '../../config';




const HomeView = ({language}) => {

    const classes = useStyles()
    const content = system_translations[language].views.home

    const [selected, setSelected] = useState(0)
    const [banners, setBanners] = useState([])
    const [section_dispatcher, setSectionDispatcher] = useState({
        imageA:false,
        imageB:false,
        main_image:false
    })

    useEffect(() => {
        window.scrollTo(0,0)
        const initModule = async() => {
            try {
                console.log('Requesting banners...')
                const params = {
                    _sort:'id:desc',
                    locale:LANG_LOCALE[language]
                }
                const request = await public_server.get('/banners', params)
                request.data.sort((a,b) => {
                    if(a.id < b.id) return -1;
                    if(a.id > b.id) return 1;
                    return 0;
                })
                setBanners(request.data)
            } catch (error) {
                
            }
        }
        initModule()
    }, [])

    const onUpdateDispatcher = (data) => {
        setSectionDispatcher({...section_dispatcher, ...data})
    }

    const onGoingNext = () => {
        if(selected === content.carrousel.length -1 ){
            setSelected(0)
        }else{
            setSelected(selected + 1 )
        }
    }

    const onChangeBanner = (idx) => {
        
        setSelected(idx)
    }


    return ( 
        <div className={classes.root} >
            <div className={classes.container}>
                
                <div className={classes.left_container}>
                    <Hidden mdUp>
                        <div >
                            <InViewMonitor onInView={()=> onUpdateDispatcher({main_image:true})} >
                                <Fade in={section_dispatcher.main_image} timeout={1500} direction='right' >
                                    <div className={classes.main_image_container}>
                                        <ImageContainer src={content.main_image} ratio='27/13' />
                                    </div>
                                </Fade>
                            </InViewMonitor>
                        </div>
                    </Hidden>
                    <div className={classes.left_side}>
                        <div className={classes.line}/>
                        <div className={classes.text_container}>
                            <Typography className={classes.text} >{content.left_side.text}</Typography>
                            <Typography className={classes.highlight_text}>{content.left_side.highlight_text}</Typography>
                        </div>
                        
                        <div className={classes.deco_container}>
                            <div className={classes.decoration}></div>
                        </div>
                    </div>
                </div>
                <div className={classes.right_container}>
                    {/* <div className={classes.carrosuel_container}>
                        <div className={classes.dots_container}>
                            {content.carrousel.map((item, index) => {
                                return(
                                    <div className={cx({[classes.dot]:true, [classes.dot_active]:selected === index})} onClick={() => onChangeBanner(index)} />
                                )
                            })}
                        </div>
                        <div className={classes.carrosuel}>
                            {banners.map((item, key) => {
                                return(
                                    <Fade in={selected===key} timeout={1000} key={key.toString()}>
                                        <div className={cx({[classes.image_wrapper]:true,[classes.hidden]:selected!==key})}>
                                            <img alt='' className={classes.image} src={item.image.url}/>
                                        </div>
                                    </Fade>
                                )
                            })}
                            
                            <div className={classes.carrosuel_button} onClick={onGoingNext}><img src={pattern} /></div>
                            
                        </div>
                    </div> */}
                    <Hidden smDown>
                        <div >
                            <InViewMonitor onInView={()=> onUpdateDispatcher({main_image:true})} >
                                <Fade in={section_dispatcher.main_image} timeout={1500} direction='right' >
                                    <div className={classes.main_image_container}>
                                        <ImageContainer src={content.main_image} ratio='27/13' />
                                    </div>
                                </Fade>
                            </InViewMonitor>
                        </div>
                    </Hidden>
                    <div>
                        <InViewMonitor onInView={()=> onUpdateDispatcher({imageA:true, imageB:true})} >
                            <div className={classes.bottom_section}>
                                
                                <Grid container spacing={5}>
                                    <Grid item md={5}>
                                        <Fade in={section_dispatcher.imageA} timeout={1500}>
                                            <div>
                                                <div className={classes.blue_line} />
                                                
                                                <div className={classes.header}>
                                                    <Typography variant='h6' style={{color:'white'}}>
                                                        {content.right_side.title}
                                                    </Typography>
                                                </div>
                                                <div className={classes.paragraph1}>
                                                    <Typography>
                                                        {content.right_side.paragraph1}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Fade>
                                        
                                        
                                        <div>
                                            <Fade in={section_dispatcher.imageA} timeout={5000} direction='right' >
                                                <div>
                                                    <ImageContainer src={content.image1} ratio='6/7' />
                                                </div>
                                            </Fade>
                                        </div>
                                    </Grid>
                                    <Grid item md >
                                        <Fade in={section_dispatcher.imageB} timeout={3000} direction='left' >
                                            <div >
                                                <ImageContainer src={content.image2} ratio='610/481' />
                                            </div>
                                        </Fade>
                                        <Fade in={section_dispatcher.imageB} timeout={2000}>
                                            <div style={{display:'flex'}}>
                                                <div className={classes.paragraph2}>
                                                    <Typography >
                                                        {content.right_side.paragraph2}
                                                    </Typography>
                                                </div>
                                                <div className={classes.lines_rectangle_deco}>

                                                </div>
                                            </div>
                                            
                                        </Fade>
                                    </Grid>
                                </Grid>
                            </div>
                        </InViewMonitor>
                    </div>
                </div>
            </div>
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 
export default connect(mapStateToProps)(HomeView) ;

const useStyles = makeStyles(theme => ({
    root:{
        padding:'100px 0px',
        background:'#1C1C1C',
        [theme.breakpoints.down('sm')]:{
            paddingTop:24,
            //background:'red'
         },  
        
        //color:'#F4F4F4',
    },
    container:{
        display:'flex',
        flexWrap:'no-wrap',
        [theme.breakpoints.down('sm')]:{
            flexWrap:'wrap',
         },
    },
    left_side:{
        marginLeft:80,
        [theme.breakpoints.down('md')]:{
            marginLeft:0,
         },
        [theme.breakpoints.down('sm')]:{
            marginTop:40
         },
        //flex:1,
    },
    left_container:{
        flex:1,
        [theme.breakpoints.down('sm')]:{
            padding:32,
            boxSizing:'border-box',
            flex:'auto',
            //background:'red'
         },
    },
    right_container:{
        width:1200,
        //paddingRight:'100px',
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            width:'100%',
            margin:0,
            boxSizing:'border-box'
         },
        
    },
    text_container:{
        //border:'1px solid white'
    },
    text:{
        width:260,
        color:'#F4F4F4',
        fontFamily:'alda',
        fontWeight:300,
        fontSize:24,
        [theme.breakpoints.down('sm')]:{
            fontSize:20,
            width:300
         },
    },
    highlight_text:{
        color:'white',
        fontStyle:'italic',
        fontFamily:'alda',
        fontWeight:400,
        fontSize:24,
        [theme.breakpoints.down('sm')]:{
            fontSize:20
         },
    },
    line:{
        width:65,
        height:3,
        background:'white',
        marginBottom:2
    },
    header:{
        padding:'16px 0px'
    },
    
    decoration:{
        width:30,
        height:85,
        background:'#184EA2',
        transform:'skewX(25deg)'
    },
    deco_container:{
        //background:'red',
        paddingTop:'42%',
        paddingLeft:18,  
        [theme.breakpoints.down('sm')]:{
            padding:'60px 0px',
            paddingLeft:18,
         },
    },
    main_image_container:{
        paddingRight:40,
        [theme.breakpoints.down('sm')]:{
            paddingRight:0
         },
    },
    bottom_section:{
        marginTop:100,
        paddingRight:40,
        boxSizing:'border-box',
        [theme.breakpoints.down('sm')]:{
           paddingRight:32,
           paddingLeft:32,
           boxSizing:'border-box',
           marginTop:20
        },
    },
    blue_line:{
        background:'#184EA2',
        height:5,
        marginBottom:16,
        //marginTop:17
    },
    paragraph1:{
        color:'#F4F4F4', 
        //textAlign:'justify',
        marginBottom:20
    },
    paragraph2:{
        width:'45%',
        marginTop:'20%',
        color:'#FFF', 
        //background:'red',
        //marginRight:60,
        //textAlign:'justify',
        [theme.breakpoints.down('xs')]:{
            width:'100%'
            //marginTop:'15%'
         },
    },
    carrosuel_container:{
        width:'100%',
        boxSizing:'border-box',
        //border:'2px solid white',
        paddingLeft:20,
        [theme.breakpoints.down('sm')]:{
            padding:'0px 32px'
         },
    },
    carrosuel:{
        width:'100%',
        //background:'red',
        position:'relative',
        paddingTop:'75%',
        boxSizing:'border-box',
    },
    image_wrapper:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        //background:'green'
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover',
        position:'absolute',
        
    },
    hidden:{
        opacity:0,

    },
    carrosuel_button:{
        position:'absolute',
        top:0,
        left:0,
        background:'#184EA2',
        width:150,
        height:60,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        color:'white',
        zIndex:5,
        cursor:'pointer'
    },
    dot:{
        background:'#5F5F5F',
        borderRadius:'50%',
        width:10,
        height:10,
        marginBottom:8,
        marginRight:8,
        '&:hover':{
            cursor:'pointer'
        }
    },
    dot_active:{
        background:'black',
        border:'2px solid #5F5F5F',
        boxSizing:'border-box'
    },
    dots_container:{
        display:'flex'
    },
    lines_rectangle_deco:{
        width:'50%',
        height:400,
        marginTop:'20%',
        marginLeft:60,
        background:'repeating-linear-gradient(-30deg, transparent, transparent 2%, #084EB6 3%)',
        [theme.breakpoints.down('xs')]:{
            display:'none'
         },
    },
    
}))