import { Button, Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import ImageContainer from '../../components/Structure/DisplayData/ImageContainer';
import InputForm from '../../components/Forms/InputForm'
import system_translations from "../../texts/system_translations"
import cx from 'classnames';
import useContactView from './useContactView';

const ContactView = ({language}) => {

    const classes = useStyles()
    const content = system_translations[language].views.contact
    const {system, form, actions} = useContactView({language, content})

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return ( 
        <div className={classes.root} >
            <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <Typography variant='body2'>{content.contact_info}</Typography>
                            <div style={{marginTop:20}} />
                            <Typography variant='body2'>{content.address}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <a target='_blank' href='https://goo.gl/maps/FvhkaqdyQj87RtLt6' >
                                <ImageContainer src={content.image} ratio='16/9'  />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item xs={12} md={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputForm data={form.name} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.company} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.email} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.phone} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.message} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={classes.button} onClick={actions.onSubmit}>{content.form.button}</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.circle_container}/>
                        </Grid>
                    </Grid>
                </Grid>

                
            </Grid>
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 
export default connect(mapStateToProps)(ContactView) ;

const useStyles = makeStyles(theme => ({
    root:{
        padding:'100px 100px',
        background:'#1C1C1C',  
        minHeight:'calc(100vh - 200px)',
        color:'#E5E5E5',
        [theme.breakpoints.down('sm')]:{
            padding:'50px 40px',
         },
    },
    button:{
        background:'#084EB6',
        color:'white',
        textTransform:'none',
        padding:'8px 20px'
    },
    circle_container:{
        marginTop:150,
        marginLeft:'30%',
        width:'50%',
        paddingTop:'50%',
        //paddingTop:'50%',
        border:'1px solid white',
        //position:'absolute',
        zIndex:2,
        bottom:0,
        right:0,
        //transform:'translate(-40%,40%)',
        zIndex:1,
        background: '#184EA2',
        borderRadius:'50%',
        filter:'blur(100px)'
    },
    
    
}))

