import practices1 from '../../assets/practices1.png'
import practices2 from '../../assets/practices/image-constitutional.jpg'
import practices3 from '../../assets/practices/administrative-image.jpg'
import generalPattern from '../../assets/practices/pattern.svg'
import constitutionalPattern from '../../assets/practices/pattern-constitutional.svg'
import administrativePattern from '../../assets/practices/pattern-administrative.svg'
import mainImage from '../../assets/practices/main.jpg'
import mainPattern from '../../assets/practices/pattern-main.svg'
import decorationPattern from '../../assets/practices/decoration.svg'
import pattern from '../../assets/practices/pattern.svg'
import admin_const from '../../assets/practices/admin_const.png'
import civil_mer_ar from '../../assets/practices/civil_mer_ar.png'

export const translations = {
    main_image:mainImage,
    main_pattern:mainPattern,
    decoration:decorationPattern,
    menu:[{id:1, label:'Administrative and constitutional litigation'}, {id:2, label:'Civil and commercial litigation, and arbitration'}],
    civil:{
        title:'Civil and commercial litigation, and arbitration',
        paragraph1:'We handle business disputes that involve relevant interests. We meticulously analyse every detail and alternative of the conflicts that our clients entrust to us, whether as actors, defendants or third parties. We develop and implement effective and insightful strategies that generate successful results for our clients before local and federal courts throughout the country.',
        paragraph2:'We vigorously represent our clients in negotiations with hostile counterparties. We identify and use all the legal mechanisms available to our clients to effectively defend them in particularly complex disputes, in which the ordinary operation and even the survival of the business depends on the outcome of the dispute.',
        paragraph3:'We have broad experience handling pre-trial measures such as injunctions, in which we have achieved relevant precedents for our clients and the Mexican legal system.',
        paragraph4:'We have developed a strong practice representing clients in appeals and even in amparo proceedings, being able to remedy substantive and procedural deficiencies, and obtaining successful results in cases that seemed lost.',
        paragraph5:{
            text:'Our recent experience includes:',
            points:[
                'Contractual and non-contractual civil liability litigation (monetary damages, liquidated damages);',
                'Disputes in which the effectiveness of the resolutions adopted by the corporate boards and committees is discussed;',
                'Disputes among shareholders of public and private companies;',
                'Disputes in which the efficacy of civil and commercial acts and their effects are fully or partially disputed;',
                'Commercial arbitrations;',
                'Judicial proceedings in support of commercial arbitrations;',
                'Civil and commercial collection actions.'
            ]
        },
        image:practices1,
        pattern:generalPattern,
        imagePattern:constitutionalPattern
    },
    constitutional:{
        title:'Amparo and constitutional litigation',
        paragraph111:'We have successfully represented our clients in hundreds of amparo trials against all types of actions by federal and local authorities nationwide. We understand, as few law firms in the country do, each of the procedural specifics of this complex constitutional procedure. The nature of our arguments and approaches frequently lead us to the Supreme Court of Justice of the Nation, body before which we have obtained a significant number of favourable judgments and relevant judicial precedents for our clients and for the Mexican legal system.',
        paragraph1:'We have broad experience in the development of solid amparo procedures, in the comprehensive and precise control of the judicial file, in offering and submitting highly technical evidence, in filing and handling motions and remedies, and in the due enforcement of amparo judgements favourable to the interests of our clients.',
        paragraph2:'We have had actively participated as leaders in some of the most relevant constitutional litigation in modern Mexican history. We advise business chambers and organizations and law firms, companies with the greatest international and national reputation, in developing comprehensive defense strategies that involve amparo proceedings in which the operation and subsistence of companies and complete economic sectors depend.',
        paragraph3:'We vigorously defend the fundamental rights of our clients by developing comprehensive defense strategies that require impeccable planning and execution.',
        image:practices2,
        pattern:generalPattern
    },  
    administrative:{
        title:'Administrative litigation',
        paragraph1:'We have extensive experience defending and representing companies against administrative acts and omissions that affect their rights and interests. We have successfully challenged countless acts of federal, state and municipal authorities. We are experts designing and implementing multidisciplinary and comprehensive strategies against acts that cause highly sensitive contingencies and corporate crises.',
        paragraph2:'Our clients generally participate in highly regulated sectors, which is why we have gained extensive and successful experience litigation before and against economic, energy, environmental, financial, and tax authorities.',
        paragraph3:'We also develop and implement strategic litigation to resolve restrictions, obstructions, and distortions that in any way prevent the proper operation of our clients. We have constantly obtained favourable results with great individual and general impact.',
        image:practices3,
        pattern:generalPattern,
        imagePattern:administrativePattern
    },
    admin_constitutional:{
        pattern:pattern,
        image:admin_const,
        title:'Administrative and constitutional litigation',
        intro:'We have broad experience defending and representing companies against administrative acts and omissions that affect their rights, interests, and legitimate expectations. We have successfully challenged countless acts issued by federal, state, and municipal authorities. We are experts designing and implementing multidisciplinary and comprehensive strategies against highly delicate acts and official decisions.',
        paragraph1:'Our clients generally operate in highly regulated sectors, which is why we have earned extensive and successful experience litigating before and against all kinds of public authorities, in a broad range of sectors. We develop and implement strategic claims that challenge restrictions and distortions that in any way prevent the proper operation of our clients. We regularly achieve favorable results of great individual and collective impact.',
        paragraph2:'We have broad experience developing creative and solid arguments, in the comprehensive and precise control of the judicial file, in offering and filing highly technical evidence, in filing and handling motions and remedies, and in the due enforcement of favorable judgements for our clients’ interests.',
        paragraph3:'We have actively intervened, in leading roles, in some of the most relevant constitutional disputes in modern Mexico. We advise business chambers and organizations, law firms, international and local companies, in developing comprehensive defense strategies that involve amparo proceedings in which the operation and the survival of companies and private economic sectors are at stake.',
        paragraph4:'The nature of our arguments and approaches frequently lead us to Mexico’s Supreme Court, before which we have obtained a significant number of favorable judgments and relevant judicial precedents for our clients and for the Mexican legal system.'
    },
    civil_mercantil_ar:{
        pattern:pattern,
        image:civil_mer_ar,
        title:'Civil, commercial litigation, and arbitration',
        intro:'We handle civil and commercial disputes, as well as arbitration proceedings that involve relevant interests. We meticulously analyze every detail of the controversies that our clients entrust to us, whether as plaintiffs, defendants or third parties. We develop and implement effective and insightful strategies that generate successful outcomes for our clients before local and federal courts countrywide.',
        paragraph1:'We vigorously represent our clients in negotiations with hostile counterparties. We identify and use all the legal mechanisms available to our clients to effectively defend them in particularly complex disputes, in which the ordinary operation and even the survival of the business depends on the outcome of the dispute.',
        paragraph2:'We have broad experience handling pre-trial measures such as injunctions, in which we have achieved relevant precedents for our clients and the Mexican legal system.',
        paragraph3:'As well, we have developed a strong practice representing clients in appeals and even in amparo proceedings, and we have been able to remedy substantial and procedural deficiencies, obtaining successful results in cases that seemed lost.',
        paragraph4:'Our recent experience includes contractual and non-contractual civil liability litigation (damages); disputes in which the validity of resolutions adopted by the corporate boards and committees is discussed, disputes among shareholders of commercial public and private companies; disputes in which the validity of civil and commercial acts and their effects are fully or partially disputed; domestic and international arbitrations; as well as judicial proceedings in support of such arbitrations, among others. '
    }
    
}

export default translations