import { Grid, Typography, makeStyles } from "@material-ui/core"


const ValuesMobile = ({ content }) => {

    const classes = useStyles()

    return(
        <div className={classes.root} >
            <div className={classes.subroot}>
                <Grid container direction='column'>
                    <Grid item>
                        <div style={{marginBottom:100}}>
                        <div className={classes.first_row} >
                            <div className={classes.deco1}></div>
                            <div className={classes.container01}>
                                <Typography variant='h1'>01</Typography>
                            </div>
                        </div>
                        <div className={classes.second_row}>
                            <div style={{padding:'20px 0px 0px', textAlign:'end'}}>
                                <div className={classes.header}>
                                    <Typography variant='subtitle2'>{content.confidence.title}</Typography>
                                </div>
                                <Typography>{content.confidence.text}</Typography>
                            </div>
                        </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.first_row}>
                            <div className={classes.deco2}></div>
                            <div className={classes.container02}>
                                <Typography variant='h1'>02</Typography>
                            </div>
                        </div>
                        <div className={classes.second_row}>
                            <div style={{padding:'20px 40px'}}>
                                <div className={classes.header} style={{borderTop:'2px solid black'}}>
                                    <Typography variant='subtitle2'>{content.solution.title}</Typography>
                                </div>
                                <Typography>{content.solution.text}</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.first_row} style={{height:200, marginTop:80}}>
                            <div className={classes.deco3}></div>
                            <div className={classes.container03}>
                                <Typography variant='h1' style={{color:'#1C1C1C'}}>0</Typography>
                                <Typography variant='h1' style={{color:'#184EA2'}}>3</Typography>
                            </div>
                        </div>
                        <div className={classes.second_row} style={{marginBottom:100}}>
                            <div style={{padding:'20px 0px', width:'73%'}}>
                                <div className={classes.header} >
                                    <Typography variant='subtitle2'>{content.dedication.title}</Typography>
                                </div>
                                <Typography>{content.dedication.text}</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.third_row}>
                            <div className={classes.deco4}></div>
                            <div className={classes.container04}>
                                <Typography variant='h1'>04</Typography>
                            </div>
                        </div>
                        <div className={classes.third_row} style={{marginBottom:100}}>
                            <div style={{padding:'20px 0px'}}>
                                <div className={classes.header} >
                                    <Typography variant='subtitle2'>{content.honesty.title}</Typography>
                                </div>
                                <Typography>{content.honesty.text}</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.third_row} style={{height:200}}>                    
                            <div className={classes.deco5}></div>
                            <div className={classes.container05}>
                                <Typography variant='h1'>05</Typography>
                            </div>
                                
                        </div>
                        <div style={{marginTop:40}}>
                            <div className={classes.header} style={{paddingTop:0}} >
                                <Typography variant='subtitle2'>{content.ethics.title}</Typography>
                            </div>
                            <Typography>{content.ethics.text}</Typography>
                        </div> 
                    </Grid>
                    <Grid item >  
                        <div className={classes.fourth_row}>
                            <div className={classes.deco6}></div>
                            <div className={classes.container06}>
                                <Typography variant='h1'>06</Typography>
                            </div>
                        </div>
                        <div className={classes.fifth_row}>
                            <div style={{padding:'20px 0px 60px', width:'80%'}}>
                                <div className={classes.header}>
                                    <Typography variant='subtitle2'>{content.excellence.title}</Typography>
                                </div>
                                <Typography>{content.excellence.text}</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item >                    
                        <div className={classes.fourth_row}>
                            <div className={classes.deco7}></div>
                            <div className={classes.container07}>
                                <Typography variant='h1'>07</Typography>
                            </div>
                        </div>
                        <div className={classes.fifth_row}>
                            <div style={{padding:'20px 40px', textAlign:'end'}}>
                                <div className={classes.header}>
                                    <Typography variant='subtitle2'>{content.undertaking.title}</Typography>
                                </div>
                                <Typography>{content.undertaking.text}</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item >      
                        <div className={classes.fourth_row}>
                            <div className={classes.deco8}></div>
                            <div className={classes.container08}>
                                <Typography variant='h1'>08</Typography>
                            </div>
                        </div>
                        <div className={classes.fifth_row}>
                            <div style={{padding:'20px 0px', width:'55%'}}>          
                                <div className={classes.header}>
                                    <Typography variant='subtitle2'>{content.sensitivity.title}</Typography>
                                </div>
                                <Typography>{content.sensitivity.text}</Typography>                    
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.bottom_rectangle}></div>
        </div>
    )

}

export default ValuesMobile

const useStyles = makeStyles(theme => ({
    root:{
        padding:'100px 0px 0px',
        background:'#1C1C1C',  
        //color:'#F4F4F4',
    },
    subroot:{
        //width:1200,
        color:'white',
        padding:'0px 40px'
       
    },
    bottom_rectangle:{
        width:'100%',
        height:50,
        background:'#303030',
        marginTop:200
    },
    header:{
        padding:'20px 0px',
        textTransform:'uppercase'
    },
    text_column2:{
        padding:'0px 20px'
    },
    first_row:{
        height:280,
        width:'100%',
        position:'relative',
        //background:'white'
    },
    second_row:{
        height:220,
        width:'100%',
        position:'relative',
        //background:'green'
    },
    third_row:{
        height:180,
        width:'100%',
        position:'relative',
        //margin:'40px 0px 100px',
        //background:'yellow'
    },
    fourth_row:{
        height:270,
        width:'100%',
        position:'relative',
        //background:'white'
    },
    fifth_row:{
        //height:180,
        width:'100%',
        position:'relative',
        //background:'white'
    },
    deco1:{
        width:'33%',
        height:'100%',
        background:'repeating-linear-gradient(80deg, transparent, transparent 3%, #084EB6 4%)'
    },
    deco2:{
        position:'absolute',
        left:'10%',
        width:'80%',
        height:'90%',
        background:'repeating-linear-gradient(70deg, transparent, transparent 2%, #C4C4C4 3%)'
    },
    deco3:{
        position:'absolute',
        bottom:0,
        width:'100%',
        height:'100%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 2%, #084EB6 3%)'
    },
    deco4:{
        position:'absolute',
        top:'50%',
        width:'100%',
        height:'50%',
        background:'repeating-linear-gradient(45deg, transparent, transparent 3%, #5F5F5F 4%)'
    },
    deco5:{
        position:'absolute',
        bottom:0,
        left:'50%',
        width:'50%',
        height:'100%',
        background:'repeating-linear-gradient(-70deg, transparent, transparent 2%, #084EB6 3%)'
    },
    deco6:{
        position:'absolute',
        bottom:'5%',
        width:'100%',
        height:'60%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 3%, #5F5F5F 4%)'
    },
    deco7:{
        width:'40%',
        height:'100%',
        background:'repeating-linear-gradient(80deg, transparent, transparent 2%, #C4C4C4 3%)',
    },
    deco8:{
        position:'absolute',
        bottom:0,
        width:'70%',
        height:'80%',
        background:'repeating-linear-gradient(-20deg, transparent, transparent 2%, #084EB6 3%)'
    },
    container01:{
        color:'#184EA2',
        position:'absolute',
        bottom:'10%',
        left:'15%',
    },
    container02:{
        color:'#1C1C1C',
        position:'absolute',
        bottom:'15%',
        left:'30%',
    },
    container03:{
        //color:'#1C1C1C',
        position:'absolute',
        bottom:12,
        left:4,
        display:'flex'
    },
    container04:{
        color:'#5F5F5F',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    },
    container05:{
        color:'#5F5F5F',
        position:'absolute',
        top:'50%',
        left:'25%',
        transform:'translateY(-50%)'
    },
    container06:{
        color:'#5F5F5F',
        position:'absolute',
        left:'50%',
        bottom:-28,
        transform:'translateX(-50%)',
        '&:webkitTextStroke':'2px black'
    },
    container07:{
        color:'#184EA2',
        position:'absolute',
        left:'50%',
        bottom:-28,
        transform:'translateX(-50%)',
    },
    container08:{
        color:'#184EA2',
        position:'absolute',
        left:0,
        bottom:-28,
        //transform:'translateX(-50%)',
    },
    
    
}))