import team from '../../assets/team/general-team2.jpg'
import team_pattern from '../../assets/team/team-pattern2.svg'
import team1 from '../../assets/team/team1.png'
import team_pattern_black from '../../assets/team/team-pattern-black.svg'


export const translations = {
    left_side:{
        text:'Tenemos un riguroso procedimiento de selección de pasantes y abogados, quienes son sometidos a un intenso y permanente entrenamiento técnico y práctico para cumplir nuestro compromiso: brindarles a nuestros clientes asesoría jurídica de excelencia.',
        image:team
    },
    image:team,
    pattern:team_pattern,
    small_image:team1,
    pattern_black:team_pattern_black,
    textContent:'We have a rigorous hiring process of clerks and lawyers, who are subject to an intense and permanent technical and practical legal training, which allows to exceed our clients’ expectations by providing them with excellent legal advice.',
    toolbar:[
        {id:1, label:'Overview'},
        {id:2, label:'Education & Experience'},
        {id:3, label:'Academy'},
        {id:4, label:'Associations'},
        {id:5, label:'Testimonials'},
    ],
    noinfo:'There is no information yet',
    
}

export default translations