import moment from "moment"
import { useState, useEffect } from "react"
import { LANG_LOCALE , public_server } from "../../config"

const useTeamView = ({ language, content}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [employees, setEmployees] = useState([])
    const [selected, setSelected] = useState(null)
    const [view, setView] = useState(1)
    

    useEffect(() => {
        window.scrollTo(0,0)
        actions.onInitModule()
    }, [language])
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            //console.log('cargando...')
            try {
                const params = {
                    //_sort:'id:desc',
                    _locale:LANG_LOCALE[language]
                }
                const request = await public_server.get('/employees', {params:params})
                //console.log(request.data)
                request.data.sort((a,b) => {
                    if(a.order < b.order) return -1;
                    if(a.order > b.order) return 1;
                    return 0;
                })

                if(request.data && request.data.length) setSelected(request.data[0])
                setEmployees(request.data)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
            //console.log('...fin')
        },
        onChangeEmployee: (_data) => {
            setSelected(_data)
            const menu = content.toolbar
            let pos = 1
            menu.every(item => {
                const temp = _data[item.key]
                if(temp !== null && temp !== undefined && temp !== ''){
                    pos = item.id
                    return false
                }
                return true
            })
            setView(pos)
        },
        onGo2NextEmployee: () => {
            const index = employees.findIndex( el => el.id === selected.id);
            let nextIdx = index + 1;
            if(nextIdx > employees.length - 1) nextIdx = 0;
            actions.onChangeEmployee(employees[nextIdx])
        },
        onChangeDataEmployeeView: (_data) => {
            setView(_data)
        }            
    }

    const system = {loading, sending, error}
    const view_data = {employees, selected, view}

    return {system, view_data, actions}
}

export default useTeamView
